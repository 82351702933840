$i_url:"../Bilder";

$outerMargin: 35px;
$mainOuterPadding:30px;
$mobileOuterMargin:15px;
$mobileOuterPadding:15px;

@import "_reset"; /* resets for different browsers and sets standards */
@import "_fontello"; /* smaller font-awesome TODO: Change icons to whats actually needed */
@import "_mixins"; /* different mixins TODO: Kill whats depr */

@import "colors"; /* color variables */
@import "font"; /* font imports, settings and mixins TODO: Kill whats depr */
@import "bgs"; /* bg settings and mixins TODO: Map for banderole Pics */

@import "menu"; /* just menu settings - TODO: kill all ui dependencies*/
@import "aside"; /* aside settings - MAYBE: break apart for each pug-module - TODO: cleanup */
@import "banderole"; /* just banderole settings - TODO: move bgs to bgs. Create Map */
@import "footer"; /* just footer settings - maybe move to main */

@import "ui"; /* all ui components arrows and stuff */

@import "mobileOnly"; /* new mobile */
@import "nojs"; /* simple no js version */

/*@import "mainDivs";*/
/*@import "start";*/
ul *{
    -webkit-backface-visibility: hidden;
}

//fix for #111 test this later - FLO: added safari-only/hack
/* Safari 6.1+ (9.0 is the latest version of Safari at this time) */

@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
    html, body{
        position: relative;
        overflow: hidden;

    }
}
// chromes fancy anchor focus
:focus {
    outline: none !important;
}
body, html {
    width: 100%;
    -webkit-text-size-adjust: 100%;
    line-height: 1;
    font-size:16px;
    letter-spacing:0.15em;
    overflow: initial;
    text-rendering:optimizeLegibility;
}

$langElements: "h1", "h2", "h3", "h4", "p", "span", "div", "a", "table", "input";

%lang_styles {
    display: none !important;
}

@mixin langEls($lang) {
    @each $item in $langElements{
        #{$item}:lang(#{$lang}){
            @extend %lang_styles;
        }
    }
}

html:lang(en){
    @include langEls("de");

}
html:lang(de){
    @include langEls("en");
}

// section,.banderole{
//     display:none;
// }
.load {
    section, .banderole{
        display:block;
    }
}
#start-main.load{display:block;}
body, html {
    height: 100%;
    width: 100%;
    -webkit-text-size-adjust: 100%;
    line-height: 1;
    font-size:16px;
    letter-spacing:0.15em;
    /*overflow: hidden;*/
    @include bp(imac){
        font-size: 20px;
    }
    @include bp(imacbig){
        font-size: 21px;
    }
}
* {
    //-webkit-backface-visibility: hidden;
}
body {
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
    font-size: .75rem;
    font-family: Titillium;
    background-color:white;
}

p,span,h1,h2 {
    hyphens: auto;
}
.mob_br{
    display:none;
}
a:hover,a:link,a:active,a:visited {
    @include fontStd;
    @include transition(color 250ms linear);
    white-space: nowrap;
}
a:hover {
    color:$hoverColor;
}
ol, ul { list-style: none;}
li {display:inline-block;}
div#mouseCon {
    height: 100%;
    display: block;
}
.scroll {
    position: absolute;
    display: none;
}
div.active {
    display: block;
    opacity: 100;
}
.anim1 {
    @include transition(all 1500ms ease);
}
.ol_bg{
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: center top;
    display: none;
    z-index:9;
}
#kontakt-main{
    z-index: 11;
}
#start-main {
    top: 100%;
    z-index: 10;
}
#suite1-main{ z-index:8;}
#suite2-main{ z-index:7;}
#suite3-main{ z-index:6;}
#service-main{ z-index:5;}
#presse-main{ z-index:4;}
div.fastAnim {
    @include transition(transform 1000ms ease-out);
}
@keyframes fade-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
#loader{
    float: left;
    z-index: 12;
    width: 100%;
    &, & #loader-bg{
        transition:  opacity 1500ms linear 500ms;

    }
    position: absolute;
    h2{
        animation: fade-in 1500ms;
        text-align: center;
        white-space: nowrap;
    }

    &.goAway{
        opacity:0;
    }
    & #loader-bg{
        width:69%;
        padding-left: 4px;
        float:left;
        box-sizing: border-box;

        @media(max-width: 1280px){  width: 65%;}
        @include bp(smallWide){     width: 60%;}
        @include bp(ipadV){         width: 100%;}
        @include bp(ipadH){         width: 60%;}
        @include bp(mobileH){       width: 100%;}
        
    }
    &.banderole4-img1{
         @include bp(smallWide){background-position: 100% 86%;}
         @include bp(ipadV){background-position: 100% 86%;}
         @include bp(ipadH){background-position: 100% 86%;}
         @include bp(mobileH){background-position: 100% 67%;}
    }
    & .banderole-aside{
        right:0;
        //margin-left: -4px;
        //padding-left: 4px;
        @include bp(smaller){   width: 35%;  }
        @include bp(smallWide){ width: 40%; }
        @include bp(ipadV){     width: 65%; }
        @include bp(ipadH){     width: 40%; }
        @include bp(mobileV){   width: 100%; }
        @include bp(mobileH){   width: 100%; }
    }
}

.svgs{
    display: none;
}

.media-query{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    z-index: 999999;
    div{
        width: 20%;
        float: left;
        background-color: red;
    }
    @include bp(ipadV){
        #ipad-v{
            background-color: green;
        }
    }
    @include bp(ipadH){
        #ipad-h{
            background-color: green;
        }
    }
    @include bp(smallWide){
        #small-wide{
            background-color: green;
        }
    }
    @include bp(mobileV){
        #mobileV{
            background-color: green;
        }
    }
    @include bp(mobileH){
        #mobileH{
            background-color: green;
        }
    }
}


body.nojs{
    .banderole-aside.aside-klapp .item-description {
        max-height: none;
    }
    .banderole-aside.aside-klapp li article.first{
        padding-top:0.3em;
    }
    @include bp(mobileH){
        .banderole-img.first + .parent-start{
            margin-left:25%;
        }
    }   
    .banderole-aside.aside-klapp .item-header span{
        display:none;
    }
    .nav-page{
        .sidemenu-text{margin-left:0;}
        i{
            display:none;
        }
    }
    @keyframes fadeOut {
        0%   { opacity: 1; max-height:100%;min-height:100%; }
        50% { opacity: 0; display:none;max-height:100%;min-height:100%; }
        100% { opacity: 0; max-height:0;min-height:0; }
    }
    #loader{
        display:block;
        animation:fadeOut 6s linear 2s 1 forwards; /* IE 10+, Fx 29+ */

        div.loader{
            display:block;
            animation:fadeOut 6s linear 2s 1 forwards; /* IE 10+, Fx 29+ */
        }
        aside{
                display:none;
            }
    }
    .banderole-img.first.banderole4-img1{
        position:absolute;
        top:0;left:0;
        animation:fadeOut 6s linear 5s 1 forwards; /* IE 10+, Fx 29+ */
        z-index:10;
    }
    .close{
        display:none;
        position: fixed;
        top: 30px;
        left: 30px;
        width: 40px;
        height: 40px;
        z-index: 3000;
        transform:translate3d(0,0, 3000px);
    }
    .burger{
        transform:translate3d(0,0,2000px);
        &:hover{
            
            left: 33px;
            top: 30px;
            height: 56.4px;
            @media (max-aspect-ratio: 1/1) and (max-width: 650px){  height: 37.6px; }
            .n1,.n2{    
                background-color: white;
                margin-left: 50%;
            }
            .n1{
                    transform: translateX(50%) rotate(45deg);    
            }
            .n2{
                transform: rotate(-45deg);
            }
            & + div#menu-con, & ~ div#mobile-menu-con {
                transform: translate3d(0,0,20px);
            }
            & ~ .close{
                display:block; 
            }
        }
    }
    
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 27.07.2016, 16:00:24
    Author     : Florian
*/
.mobExtra{
    display: none;
}
body.mobileOnly, html.mobileOnly {
    position: static;
    overflow: visible;
    height:auto;
    max-height:none;
}
.banderole{
    
}
body.mobileOnly {
    section{
        transform:translate3d(0,0,0);
    }
    /*    max-width: none; */
    /*-webkit-overflow-scrolling: touch;*/
    a { -webkit-user-select: none !important; }
    .ol_bg, .scroll{
        display: block;
    }
    #service-packages{
        position: relative;
    }
    section.ol_bg:not(#start-main){
        display:none;
    }
    #presse-main, section#service-main{
        /* haben mobile Version */
        display: none;
    }
    div#mouseCon {
        height: auto;
        display: block;
    }
    .ol_bg{
        width: 100%;
        /*min-height: 100vh; warum???*/
        &#kontakt-main{
            min-height: 0;
        }
        height:auto;
        background-position: center top;
        z-index:9;
        &#kontakt-main{}
        &#start-main {top: auto;}
    }
    .scroll{
        position:relative;
    }
    @for $i from 0 through 5{
        @if $i==0{
            #start-main{z-index:1;}
        }@else if $i==5{
            .mobExtra{z-index:1+$i*2;}
        }
        @else{
            .page#{$i+1}{
                z-index:1+$i*2;
                .banderole-next-fixed{
                    z-index:2+$i*2;

                }
            }
        }
    }
    #loader{
        height:100%;
        z-index:201;
        #loader-bg,aside{
            height:100%;
            margin:0;padding:0;
        }
        aside.loader{
            display:none;
        }
    }
    .start .banderole-img-wrapper{
        &>div:not(.first),&>aside:not(.id-start){
        display:none;
        }
    }
    .bndClipper{
        position:relative;
        float:right;
        width:auto;
        height:100vh;
        top:0;
        left:0;
        right:0;
        bottom:0;
        clip: rect(auto, auto, auto, auto);

        /*
        unwanted result in Chrome. Check if needed on different browsers
        clip-path: inset(0px 0px 0px 0px);*/
    }
    .banderole{
        position:relative;
        padding:0;
        max-height:100%;
        max-width:100%;
        max-height:100vh;
        max-width:100vw;
        box-sizing: border-box;
        overflow:hidden;
        &.banderole-active{
            overflow-x:visible;
        }
    }
    & .noVP {   
        section:not(.mobExtra){
            overflow-y:hidden;
        }
        .banderole{
            min-height:100%;
            max-height:100%;
            max-width:100%;
        }
        .bndClipper{
            height:100%;
        }
        .banderole-img-wrapper{
            min-height: 100%;
        } 
        .banderole-img{ 
            min-height:100%;
            height:100%;
            max-height:100%;    
        }
        .banderole-aside{
            min-height:100%;
            max-height:100%;
            height:100%;
            box-sizing:border-box;
            /*padding:0px 5%  0px;*/
            section{
                position:relative;
                height:100%;
                margin:0;
                padding:10%;
                box-sizing:border-box;
                
            }
        }
        .mobExtra{
            .banderole{
                max-height:none;
                min-height:none;
            }
            .banderole-img{    
                min-height:0;
                height:55%;            
                max-height:55%;
                @include bp(mobileH){
                    height:100%;
                    max-height:100%;
                }
            }
            
            .banderole-aside{
                max-height:none;
                min-height:0;
                height:auto;
                section{
                    height:auto;
                }    
                li{
                    max-height:none;
                }
            }
            .banderole-img-wrapper{
                min-height: 0;
            } 
        }
    }
    .banderole-img-wrapper{
        max-height:100%;
        max-width:100%;
        overflow-y:hidden;
        box-sizing: border-box;
    }
    .banderole-img.first{
        position:relative;
        border:0;
    }
    .banderole-img.first + aside{
        margin-left:0;
    }
    :not(#start-main) .banderole:not(.banderole-active) .banderole-img:not(.first){
        display:none;
    }

    footer {
        position:relative;
        text-align: left;
    }
    .burger.burger-vertical{
        position:fixed;
        top:20px;
        left:10px;
        &.loading div{
            background-color:white;
        }
        &.active {
            left: 33px;
            top: 30px;
        }
    }
    .banderole-next-fixed{
        top:0;
        bottom:auto;
        padding:20px;

    }
    .banderole.scrolled{
        .banderole-nav-left{display:none;}
        .banderole-nav-right, .banderole-nav-left{
            top:0;
            transform: none;
            padding:19px;
            .svg-wrapper{
                width: 28px;
                height: 28px;
            }
            svg{
                stroke:#474a50;
                stroke-width:0.15em;
                height: 28px;
            }
        }
    }
    .aside-top:not(.language-select){
        padding:17px;
        top:auto;
        bottom:0;
        li .svg-wrapper{
            height: 28px;
            width: 28px;
            margin-top:-14px;
            transform:rotateZ(180deg);
        }
    }
    .aside-bottom{
        bottom:auto;
        top:0;
        padding:17px;

        li .svg-wrapper{
            height: 28px;
            width: 28px;
        }
    }
        .language-select{
            top:15px;
            bottom:auto;
            transform:translate3d(-50%,0,0);
            margin:0;
            padding:0;
            
        }
    @include bp(mobileH){
        
        .aside-top:not(.language-select){
            padding: $mobileOuterPadding;
            padding-right: 0;
            right: 55px;
            left: auto;
            transform: none;
            .sidemenu-text{
                margin-left:0;
                margin-right: 2.2rem;
            }
            .arrow{
                left: auto;
                right: 0;
            }
        }

        .aside-bottom{
            padding: $mobileOuterPadding;
            padding-right: 0;
            right: 55px;
            left:auto;
            transform: none;

        }
    }
    .logo-con{
        top:55%;
        @include bp(ip5v){
            top:48%;
        }
        margin-top:-50%;
        @include bp(mobileH){ display:none;};
        @include bp(ip4v){margin-top: -34%;}
    }

       

    .start .sidemenu-wrapper{
        top:70%;
        padding:0;
        @include bp(mobileH){
            top:50%;
            bottom:auto;
            padding:0;
            height:6.6em;
            margin-left: -1em;
        }
        @include bp(ip4v){ top:72%; }
        li .svg-wrapper.arrow-up{
            transform: rotate(180deg);
        }
        ul{
            position:relative;
        }
        &.social-buttons{
            padding-left:0;
            padding-right:0;
            top:80%;
            @include bp(mobileV){
                top:84%
            }
            @include bp(mobileH){
                top:auto;
                bottom:20px;
                margin-left:0.65em;
                height:auto;
                padding:0;
                transform:translate3d(-50%,0,0);
            }
            @include bp(ip4v){
                // top:auto;
                // bottom:20px;
                margin-left:0.65em;
                height:auto;
                padding:0;
                transform:translate3d(-50%,0,0);
            }
        }
    }
    .start .sidemenu-wrapper:not(.social-buttons){
        #l_gotoSuite{
            position:absolute;
            top: 100%;
        }
        #l_gotoContact{
            position:absolute;
            top: 200%;
        }
    }
    
    .banderole-next.simple{
        display:none;
    }
    #service-main.banderole-img{
        @include bp(mobileH){
            background-position-y: 71%;
        }
    }
    .mobExtra, #service-main{
        .banderole{max-height:none;}
        .banderole-img-wrapper{display:block; max-height:none;}
        .banderole-img, aside{ display:block; width:100%; max-width:100%; margin:0; padding:0; max-height:none;border:0;}
        .banderole-img{ 
            height:50vh;
}
        aside{height:auto;}
        aside section {
            width:100%;
            padding:13%;
            &#preise{
                padding-top: 1%;
            }
            @include bp(mobileH){
                padding:7%;
            }
            box-sizing: border-box;
        }
        .banderole-next,.banderole-next-fixed{
            display:none;
        }
        .scroll-up{
            display:none;
        }
    }
    #kontakt-main{
        position:relative;
        z-index:10;
        height:auto;
        footer {
            height:auto;
            padding:10%;

        }
        p{
            height:auto;
            position:relative;
            left:auto;
            top:auto;
            transform:none;
            box-sizing: border-box;
            max-width:100vw;
            padding:0;
            padding-bottom:0;
            text-align: left;
        }

        span{display:block;}
        span.trenner{
            display:none;
        }
    }
    .banderole-img{
        -webkit-user-select: none;
        will-change:transform;
        @include bp(mobileV){
            &:not(.first){
                min-width: 200%;
            }
            // &.with-aside{
            //     min-width:100%;
            // }
        }
    }
    @include bp(mobileH){
        .banderole-img.with-aside.banderole4-img1{
            background-position: 50% 57%;
        }
    }

    .mobExtra .banderole-img.with-aside{
        min-width:100%;
    }
    .banderole5-img1, .banderole5-img2{
        min-width: 100%;
    }
    .banderole4-img2 {
        background-position: 50% 35%;
    }
    .banderole4-img3 {
        background-position: 50% 86%;
    }
    .banderole4-img4 {
        background-position: 50% 57%;
    }
    .banderole4-img5 {
        background-image: url("../Bilder/banderole6_1.jpg") !important;
        background-position: 50% 57%;
    }
    .banderole5-img1.first:before{
        background-size: 270%;
        background-position: 44% 56%;
    }
    .banderole5-img2{
        background-position:54% 74%;
    }
    #menu-con{
        display:none;
    }
    #mobile-menu-con{
        display:block;
    }
    li#l_buchen {
        padding-top: 2em;
    }
    .banderole-aside{
        &:first-of-type {
            width: 100%;
        }
        padding: 0;
        #suite1,#suite2,#suite3 {
            h1 {
                font-size: 4rem;
                margin-bottom: 5%;
                @include bp(mobileV){
                    font-size: 3.1rem;
                    margin-bottom: 5px;
                }
            }
        }
        &.aside-klapp{
            .item-header{
                pointer-events:none;
                h2{
                    padding-bottom: 0.3em;
                }
            }
            li.item:not(:first-of-type) {
                margin-top:2em;
            }
        }
    }
    .aside-cta{
        .item-icon{
            line-height:2.2em;
        }
    }
    .vertical-center-wrapper{
        transform:translate3d(0,-60%,0);
        @include bp(ip5v){
            transform:translate3d(0,-50%,0);
        }
    }
    .mobExtra .item .item-description{
        h2 {
        padding-top: 0.5em;
        }
        max-height: none;
    }
    
    .mobExtra .item .item-header svg{
        display: none;
    }
    td:last-of-type {
        text-align: right;
        width: auto;
        white-space:nowrap;
        .mob_br{
            display:inline;
        }
    }
}


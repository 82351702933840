// Variables for Fonts declared in _font.scss
$asideFontSize: $defFontSize;
$asideLineHeight: $defLineHeight;
$asideLetterSpacing: $defLetterSpacing;
$asideFontColor: $grey;
$asideBgColor: #fff;
$outerPadding: $mainOuterPadding;
$marginTop: 60px;
//$imacbigfontsize: 20px;


//general scss for every aside
// normally this is not really for EVERY aside, but for every aside so far. Better to make it classbased?
aside {

}
table{
    width:100%;
    color: $grey;
}
td{
    width:auto;
    padding-bottom: 2px;
    &:last-of-type{
        text-align:right;
        width:11em;
    }
}

.aside-top {
    /*position: absolute;*/
    top: 0;
    /*left: 50%;*/
    /*transform: translate3d(-50%, 0, 0);*/
    /*width: auto;*/
    /*height: auto;*/
    /*margin: -.5em 0 0 0;*/
    ul{
        /*Optischer Randausgleich bei Icons*/
        margin-top:-.2em;

    }
}
.aside-bottom {
    /*position: absolute;*/
    bottom: 0;
    /*left: 50%;*/
    /*transform: translate3d(-50%, 0, 0);*/
    /*width: auto;*/
    /*margin-bottom: 3px;*/
    ul{
        /*Optischer Randausgleich bei Icons*/
        margin-bottom:-.2em;
    }
}
.id-packages .simple{
    display:none;
}
.banderole-aside{
    .simple {
        .svg-wrapper {
            margin-top:0.3em;
        }
    }
}

.sidemenu-wrapper, .aside-bottom, .aside-top {
    position: absolute;
    left: 50%;
    transform: perspective(1px) translate3d(-50%, 0, 0);
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    padding: $outerPadding;
    letter-spacing: .15em;
    &.banderole-next, &.banderole-next-fixed{
        @include bp(mobileV){
            padding: $mobileOuterPadding;
        }
        @include bp(mobileH){
            padding: $mobileOuterPadding;
        }
    }

    li {
        position: relative;
        box-sizing: border-box;
        height: auto;
        display: block;
        font-size: $defFontSize;
        // @include bp(imacbig){
        //     font-size: 1rem;
        // }
        // @include bp(imacbig){
        //     font-size: 1rem;
        // }
        line-height: 2.6em;
        a {
            color: $grey;
            font-weight: normal;
        }
        &:hover a{
            color: $warmGrey;
            &::before,&::after {
                border-color: $warmGrey;
            }
        }
        a {
        }
        &.active {
            .arrow{
                &,&::after,&::before {
                    border-color: $warmGrey;
                    color: $warmGrey;
                }
            }
        }
        .svg-wrapper{
            svg{
                stroke: $grey;
                fill: $grey;
            }
            position: absolute;
            top: 50%;
            height: 2em; //adjust margin-top when adjusting height
            margin-top: -1em; // half of svg height
            width: 2em;
            @include bp(mobileV){
                height: 1.7em; //adjust margin-top when adjusting height
                margin-top: -0.85em; // half of svg height
                width: 1.7em;
                left: 0;
            }
            /*            &.arrow-up {
                            transform: rotate(-90deg);
                        }
                        &.arrow-down {
                            transform: rotate(90deg);
                        }*/
        }
        /*        i{
                    left: 0;
                    position: absolute;
                    top: 50%;
                    transition: border-color .25s linear;
                    &.arrow-up {
                        transform: translateY(-50%) rotate(-90deg);
                    }
                    &.arrow-right,
                    &.book {
                        transform: translateY(-50%);
                    }
                    &.arrow-down {
                        transform: translateY(-50%) rotate(90deg);
                    }
                    &.book::after {
                        border: solid 1px $grey;
                    }
                }*/
    }
}

.social-buttons {
  a, svg {
    display: inline-block;
  }
}

.start,aside.loader {
    .sidemenu-wrapper {
        margin-left:  -.8em;
        top: 60%;
        transform: translate3d(-50%, -50%, 0);
        @include bp(smallWide){
            top:62.5%;

        }
        @include bp(mobileV){
            bottom:0;
            margin: 0;
            padding: 18% 15%;
            max-width: 100%;
        }
        @include bp(mobileH){
            bottom:0;
            margin: 0;
            padding: 18% 15%;
            max-width: 100%;
        }
    }
    .social-buttons{
        margin-left: 0.1em;
        /*top:74%;*/
        top: 70%;
        clear: both;
        padding-top: $outerPadding;
        svg {
          width: 1.1rem;
          height: 1.2rem;
        }
        .facebook {
          width: 0.38rem;
          margin-right: 0.1em
        }
        a:link,
            a:active,
            a:visited {
            color: $grey;
        }
        a {
          margin: 0.28em;
        }
        a:hover {color: $warmGrey}
        @include bp(smallWide){
            top:74%;
        }
        @include bp(mobileV){
            /*display:none;*/
            margin-left:0px;
        }
        @include bp(mobileH){
            /*display:none;*/
            margin-left:0px;
        }
        @media(max-height: 550px){
            /*display:none;*/
        }
    }
    .aside-top {
        margin-left: -.3em; // logo isn't metrical in the middle
    }
    .sidemenu-li {
        /*line-height: 3em;*/
        line-height: 2.2em;
        @include bp(mobileV){
            /*line-height:2.5em;*/
        }
    }
}
aside.loader{
    top:0;
    right:0;
    z-index:11;
    .sidemenu-wrapper{
        margin-left:0;
    }
}
.banderole-aside.aside-cta {
    @media (max-height:320px){
        p{
            margin:0;
            padding:0 25px;
        }
    }
    .sidemenu-wrapper {
        margin-top: 1em;
        @include bp(mobileV){
            position: relative;
            transform: none;
            left: auto;
            text-align: center;
            ul{
                display: inline-block;
            }
        }
        @media (max-height:320px){
            margin:0;
            padding:0;
            padding-top:1em;
        }
    }
    .sidemenu-li {
        line-height: 3em;
        .arrow-book{
            font-size: 58%;
            margin-left: 0.78em;
        }
        @include bp(mobileV){
            line-height: 2.8em;
        }
    }
}

.logo-con {
    position: absolute;
    top: 40%;
    left:0;
    margin-top: -7em;

    width: 100%;
    box-sizing: border-box;
    @include bp(mobileV){
        margin-top:-25%;
        /*        position:relative;
                margin:0;
                padding:18% 15%;
                max-width:100%;*/

    }
}
.logo {
    /*margin-top: 256px;*/
    margin-bottom: 60px;
    max-width: 350px;
    width: 60%;
    /* margin-left: 20%;*/
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @include bp(smallWide){
        width:50%;
    }
    @include bp(ipadH){
        width: 65%;
    }
    @include bp(mobileV){
        width:70%;
    }
    @include bp(mobileH){
        width:80%;
    }
}
// .language-select{
//     @include bp(mobileV){
//         position:fixed;
//         left:10%;
//         margin:0 0 0 20%;
//         padding-left:20%;
//     }
// }

.start .language-select{
    @include bp(mobileV){
        margin-left:0;
    }
}
.first + aside .vertical-center-wrapper {
    //margin-top: -9em;
    @include bp(mobileV){
        margin-top: 0;
    }
    @include bp(mobileH){
        margin-top: 0;
    }
    // @include bp(ipadH){
    //     margin-top: -10.5em;
    // }
}
.vertical-center-wrapper {
    /*for within the banderole*/
    // position: absolute;
    // top: 40%;
    /* maybe only for 1280*800 */

    //top: 35%;
    // left: 0;
    // margin-top: -31px;
    @include transformCenterV;
    margin-top: 0;
    left:0;
    // @include bp(ipadV){
    //     @include transformCenterV;
    //     margin-top: 0;
    // }
    // @include bp(ipadH){
    //     @include transformCenterV;
    //     margin-top: 0;
    // }
    // @include bp(smallWide){
    //     @include transformCenterV;
    //     margin-top: 0;
    // }
    // @include bp(mobileV){
    //     @include transformCenterV;
    //     margin-top: 0;
    // }
    // @include bp(mobileH){
    //     @include transformCenterV;
    //     margin-top: 0;
    // }
    // @include bp(laptop){
    //     @include transformCenterV;
    //     margin-top: 0;
    // }
    &.first {    margin-top: -5em; } // TODO: -60px comes from logo-height. should be responsive
    &.small {    margin-top: 0;   }
    /*good solution, but here: refers to 1st page!*/
    /*    position: absolute;
            top: 50%;
            transform: translateY(-50%);*/

}
.mob{
    display:none;
    @include bp(mobileV){
        display:block;
    }
    @include bp(mobileH){
        display:block;
    }
}
.std{
    display:block;
    @include bp(mobileV){
        display:none;
    }
    @include bp(mobileH){
        display:none;
    }
}
//specific for banderole-aside
.banderole-aside {
    /* Positioning */
    position: relative;
    width: 31%;
    height: 100%; //use 100% (fallback)
    /*--FLO: 100vh leads to prob with centering on vertical-center-wrapper on iOS*/
    height: 100vh; // or 100vh for firefox
    /*box-sizing:border-box; leads to prob with negative margin */

    vertical-align: top;

    // left:4px;
    // margin-left: -4px;
    // padding-left:4px;

    /* Display & Box Model */
    display: inline-block;
    border:none;

    /* Color & BG */
    background-color: $asideBgColor;
    color: $asideFontColor;

    /* Text */
    text-align: center;
    font-size: $defFontSize;
    line-height: $defLineHeight;
    letter-spacing: $defLetterSpacing;
    white-space: normal;
    @include bp(smaller){   width: 35%;  }
    @include bp(smallWide){ width: 40%; }
    @include bp(ipadV){     width:65%; }
    @include bp(ipadH){
        #start & {width: 42%; }
&:first-of-type  {width: 40%; }
    }
    @include bp(mobileV){   width:100%; }
    @include bp(mobileH){   width: 100%; }
    // @include bp(imac){
    //     font-size: 14px;
    //     line-height: 1.6em;
    // }
    // @include bp(imacbig){
    //     font-size: $imacbigfontsize;
    // }
.arrow-book{
            font-size: 58%;
            margin-left: 0.78em;
            stroke-width:2.5px;
            svg{
                stroke-width: 2.5px;
            }
    &.id-start{

        }
        @include bp(mobileH){
            width: 40%;
            min-width: 0;
        }
    }

    section {
        margin-top:5em;
        padding:0 19%;
        text-align: left;
        @include bp(smaller){
            padding: 14% 16%;
        }
        @include bp(smallWide){
            padding: 14% 14%;
            /*padding:$outerPadding/2 $outerPadding*2 - 5px $outerPadding $outerPadding*2 - 5px;*/
            &#preise{
                //padding-top: 1%;
            }
        }
        @include bp(ipadV){
            padding: 14% 14%;
        }
        @include bp(ipadH){
            padding: 14% 14%;
            &#preise{
                //padding-top: 1%;
            }
        }
        @include bp(mobileV){
            padding-top:14%;
            padding:10%;
            width:100%;
            box-sizing: border-box;
            margin-right: 0;
        }

    }
    p {
        padding: 5% 19%;
        text-align: justify;
        @include bp(smaller){
            padding: 2% 16%;
        }
        @include bp(smallWide){
            padding:$outerPadding/2 $outerPadding*2 - 5px  $outerPadding $outerPadding*2 - 5px;
        }
        @include bp(mobileV){
            padding: 7.5px 40px 15px 40px;

        }
        @include bp(mobileH){
            padding: 0px $outerPadding*2 - 5px  $outerPadding $outerPadding*2 - 5px;
        }


    }
    h1,h1.small, h2  {
        @include fontGaramondRegula;
        line-height: 1em;
        height: 1em;
    }
    h1{
        font-size: 4rem;
        margin-bottom: 5%;
        text-align:center;
        @include bp(smallWide){
            /*font-size:3.1rem;*/
            /*margin-bottom:$outerPadding/2+10px;*/
            margin-bottom:15.5px;
        }
        @include bp(mobileV){
            font-size: 3.1rem;
            margin-bottom: 5px;
        }
        @include bp(mobileH){
            font-size: 3.1rem;
            margin-bottom: 10px;
        }

    }

    h1.small, h2 {
        font-size: .75rem;
        text-transform: uppercase;
        text-align: left;
        line-height: 1.3em;
        height:auto;
        // @include bp(imac){
        //     font-size: 14px;
        // }
        // @include bp(imacbig){
        //     font-size: $imacbigfontsize;
        // }
    }
    h1.small,.aside-klapp{
        letter-spacing: .15em;

    }
    h2.bottom   {
        margin:0 0 .25em;
        padding-top:2em;
        color: inherit;
        font-size: inherit;
        font-family: inherit;
        font-weight: bold;
        text-transform: none;
    }
    a:link,
        a:visited,
        a:active {
        color: $grey;
        font-weight: normal;
        text-transform: none;
    }
    a:hover {
        color: $warmGrey;
    }
    #preise {
            margin-top: -1.6em;
        }
    &.aside-vt section,&.aside-klapp section{
        margin-top:0;
        &:first-child {
            padding: 20% 19% 12%;
        }
        padding: 0 19% 19%;
        @include bp(smaller){
             &:first-child {
                padding: 20% 16% 12%;
            }
            padding: 0 16% 14%;
        }
        @include bp(smallWide){
            &:first-child {
                padding: 14% 14%;
            }
            padding: 0 14% 14%;
            /*padding:$outerPadding/2 $outerPadding*2 - 5px $outerPadding $outerPadding*2 - 5px;*/
            &#preise{
                //padding-top: 1%;
            }
        }
        // @include bp(ipadV){
        //     padding: 14% 14%;
        // }
        @include bp(ipadH){
            &:first-child {
                padding: 21% 14% 13%;
            }
            padding: 0 14% 14%;
            &#preise{
                //padding-top: 1%;
            }
        }
        @include bp(mobileV){
            /*old*/
            padding: 15%;
        }
        @include bp(mobileH){
            margin-top: 0px;
            padding: 0px 55px 30px 55px;
        }
        @include bp(mobileV){
            &:first-child {
                padding-top: 14%;
            }
            padding: 10%;
            width:100%;
            box-sizing: border-box;
            margin-right: 0;
        }

    }
    &.aside-klapp, &.aside-vt{
        h1.small {
            margin-bottom: 2.2em;
        }
        p{
            padding:0;
        }
    }
    &.aside-vt{
        section:first-child{
            padding: 20% 19%;
        }
        @include bp(mobileV){
            max-height:100%;
            width:auto;
            box-sizing: border-box;
            .vertical-wrapper{
                p{
                    /*                column-count: 2;
                                    -webkit-column-gap: 3.8em;
                                    -moz-column-gap: 3.8em;
                                    column-gap: 3.8em;
                                    width: 41.5em;*/
                }
            }

        }
    }
    &.aside-klapp {
        header {
            width: 100%;
            margin-bottom:2rem;
        }
        header > h1 {
            float: left;
        }
        h1, h1.small {
            @include fontGaramondRegula;

            margin: 0;
            margin-bottom: 1em;
            padding-right: 10px;
            text-transform: uppercase;

        }
        p.und {
            @include fontGaramondRegula;
            margin: 2em 0;
        }
        .bottom-line {
            border-bottom: 1px solid rgba(71, 74, 80, 0.8);
            height: 0.8em;
            overflow: hidden;
        }
        li {
            /*padding: .5em 0 1em 0;*/
            width: 100%;
            @include bp(imac){
                padding: 0.25em 0 0.5em 0;
                &.sidemenu-li {
                    padding:0;
                }
            }
        }
        li .item-description:last-child article {
            //  @include bp(desktop){
            //     padding: 0 0 .25em 0;
            // }
        }

        li article {
            padding: 0 0 .5em 0;
            @include bp(imac){
                padding: 0.25em 0 0.75em 0;
            }
            #presse &.first{
                padding-top:0.9em;
            }
            h2{
                padding-top:0;
            }
            p{
                text-align: justify;
                padding:0;
            }
        }
        .item-header{
            &:hover,&:active{
                color:$warmGrey;
                stroke:$warmGrey;
                cursor: pointer;
            }
        }
        .item-description {
            clear: both;
        }
        .item-description {
            max-height: 0;
            overflow: hidden;
            transition: all 1s ease 0s;
        }
        .item.active .item-description {
            max-height: 100%;
            /*transition: max-height 1s ease;*/
            transition: all 1s ease 0.75s;
            &:last-of-type{
                margin-bottom:1em;
                @include bp(imac) {
                    margin-bottom:0.5em;
                }
            }
        }

        ul .item {
            max-height: 100vh;
            opacity: 1;
            overflow: hidden;
            transition-delay: 0.75s;
            transition-duration: 1s;
            transition-property: max-height, opacity;
        }
        .item .item-icon {
            transition: transform 1s ease;
            transform-origin: 50% 50%;
        }
        .item.active .item-icon {
            transition: transform 1s ease;
            transform: rotate(-180deg);
            transform-origin: 50% 50%;
        }
        .price_table {
            clear: both;
        }
        .item-header {
            cursor: pointer;
            h2 { float: left;}
            .item-icon {
                margin-top: -0.6em;
                float: right;
                .svg-wrapper{
                    height: 2.4em;
                    width: 2.4em;
                    svg{
                        @include fontGaramondRegula;
                        stroke: $grey;
                    }
                }
            }
        }
    }

    // icon moved to ui.scss
    &.id-golfmuseum{
        @media (max-height: 900px){
            .par1 {
                display: none;
            }
        }
    }
}
#presse, #presse-mob{ h2.aside-klapp{
    line-height:2em;
    &:first-line{
        line-height:1.3em;
    }
}
}
#presse .banderole-aside.aside-klapp ul .item {
  @include bp(imac){
      padding: 0.25em 0 1em 0;
  }
}

$outerPadding:$mainOuterPadding;
//icon
/*svg{stroke-width:0.00015em;}*/
li svg{stroke-width:2.5px;}

svg.arrow-right,svg.arrow-left{
    margin-left:0.8em;
}
.banderole-next-fixed li svg{
    /*margin-left: 0.78em;*/
}
svg{
    height: 100%;
    width: 100%;
}
.sidemenu-wrapper li .svg-wrapper, .aside-bottom li .svg-wrapper, .aside-top li .svg-wrapper,.banderole-next-fixed li .svg-wrapper{
    position: absolute;
    top: 50%;
    height: 2em;
    margin-top: -1em;
    width: 2em;
    svg{
        transition:stroke 200ms linear;
        stroke: #474a50;
        fill: #474a50;
    }
}


i.fa:not(.fa-facebook):not(.fa-instagram):not(.fa-tripadvisor) {
    border-radius: 100%;
    /*background: linear-gradient(90deg, #FFC0CB 50%, rgba(0, 255, 255, 0) 50%),linear-gradient(180deg, rgba(255,0,0,0.8) 50%,transparent 50%);*/
    font-size: 0.8rem;
    @include bp(mobileV){
        font-size: 0.6875rem;
    }
    height: 1.7em;
    width: 1.7em;
    overflow: hidden;
    border: 1px solid $grey;
    // position: relative;
    &:before {
        /*                transition: color 0.25s linear 0;*/
    }
    &.here:before,&.book:before {
        content:"\00b7";
        transform:translate3d(-41%, -55%, 0);
        margin-top: 0;
        margin-left: 0;
    }
}
i.fa-angle-right,i.fa-angle-up,i.fa-angle-left, i.fa-angle-down {
    &:before {
        content:"\203A";
    }
}
// i.fa-angle-left {transform:rotate(-180deg);}
// i.fa-angle-up {transform:rotate(-90deg);}
// i.fa-angle-down {transform:rotate(-270deg);}


i.book:after {
    content: "";
    position: absolute;
    height: 50%;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    border: solid 1px white;
    transition: border-color 250ms linear 0;
    border-radius:100%;

    .sidemenu-icon & {
        border-color:$grey;
    }

}
i:not(.fa-facebook):not(.fa-instagram):not(.fa-tripadvisor):before {
    @include fontGaramondRegula;
    font-size: 2.4em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform:translate3d(-35%, -55%, 0);
    //margin-top: -0.05em;
    //margin-left: 0.05em;
    line-height: 1;
}
.circle1 {
  position: relative;
  height: 26px;
  width: 26px;
  font-size: 0.8rem;

  overflow: hidden;
  border: 1px solid #474a50;
  border-radius: 100%;
  box-sizing: border-box;
  display: block;
  line-height: 100%;
}
.innerArrow {
  font-family: "AdobeGaramondW01-Regula";
  font-size: 30px;
  position: absolute;
  display: block;
  box-sizing: border-box;
  content: "\00b7";
  height:100%;
  width:100%;
  line-height: 50%;
  text-align: center;
  border:solid 1px transparent;
  vertical-align:middle;
}
.sidemenu-icon {
    clear:both;
    float:left;
    width:25%;
}
.sidemenu-text {
    /*Analog zum Menü*/
    /*float:left;*/
    text-align: left;
    margin-left: 2.2rem;
    #menu-con &, #mobile-menu-con &{ margin-left:0;}
    text-transform:uppercase;
    white-space: nowrap;
    transition:color 250ms linear;

    // @include bp(imacbig){
    //     margin-left: 3rem;
    // }

}
.sidemenu-li {
    /*Analog zum Menü*/
    position: relative;
    box-sizing: border-box;
    font-size: $defFontSize;
    line-height: 2.6em;
    height: 2.6em;
    display: block;
    letter-spacing:0.15em;
    // @include bp(imac){
    //     font-size:14px;
    // }
    .sidemenu-text:hover, &:hover .sidemenu-text{
            color: $warmGrey;
        }
    a {
        color:$grey;
        #menu-con & , #mobile-menu-con &{
            color:white;
        }
    }

    &:hover {
        color: $warmGrey;
        svg {
            stroke: $warmGrey;
        }
    }
    i {
        position:absolute;
        left:0;
        top:50%;
        transition: border-color 500ms linear;

        &.arrow-up {
            transform: translateY(-50%) rotate(-90deg);
        }
        &.arrow-right, &.book {
            transform: translateY(-50%);
        }
        &.arrow-down {
            transform: translateY(-50%) rotate(90deg);
        }
        &.book:after {
            border: solid 1px;
        }
    }
}
//banderole-next in aside is used with aside-bottom
.banderole-next, .banderole-next-fixed {
    /* Display & Box Model */
    //margin-bottom: 3px;
    padding: $outerPadding;
    width: auto;

    /* Color & BG */
    color: $grey;

    /* Text */
    text-transform: uppercase;
    white-space: nowrap;
    height:30px;
    // @include bp(ipadH) {
    //     padding: 30px 30px 90px;
    // }
    // @include bp(ipadV){
    //     padding: 30px 30px 90px;
    // }
    a:link, a:visited, a:active {
        &, & div {
            color: $grey;
        }
    }
    i {
       /*        background-color: rgba(255,255,255,0.7);*/
    }
    .sidemenu-icon {
        clear: both;
        float: left;
        width: 25%;
    }
    // @include bp(mobileV){
    //     display:none;
    // }
    // @media (max-height: 730px){
    //     display: none;
    // }
    @include bp(mobileV){
        padding: $mobileOuterPadding;
    }
}
.banderole .banderole-next-fixed{
    position: fixed;
    bottom: 0;
    left: 69% + 15.5%;
    z-index: 200;
    transform: translate3d(-50%, 0, 0);
    @media (max-width: 1100px){
        left: 80%;
    }
    //adjust left values of button to width of aside
    @include bp(ipadH){
        left: 84.5%;
    }
    @include bp(ipadV){
        left: 67.5%;
    }
    @include bp(smallWide){
        /* greift schon bei 1280x800, da ist aside normal breit */
        /*left: 83%;*/
    }
    @include bp(mobileV){
        left: 50%;
        padding: $mobileOuterPadding;
    }
    @include bp(mobileH){
        right: 55px;
        left: auto;
        padding: $mobileOuterPadding;
        padding-right: 0;
        transform: none;
        .arrow{
            left: auto;
            right: 0;
        }
        .sidemenu-text{
            margin-left:0;
            margin-right: 2.2rem;
        }
    }
}
.banderole-next,
.banderole-next-fixed,
.banderole-nav-right,
.banderole-nav-left{
    cursor: pointer;
    .svg-wrapper{
        position: static;
    }
    &:hover svg {
        stroke: $warmGrey;
    }
}

.banderole-nav-right, .banderole-nav-left {
    z-index: 200;
    position:fixed;
    display: none;
    padding: $outerPadding;
    width: auto;
    .svg-wrapper{
        width: 2em;
        height:100%;
        margin:0;
        position:relative;
        top:0;
        svg{
            stroke-width:1.5px;
            stroke:white;
        }
    }
    .sidemenu-text {
        display: none;
    }
}

#navigation--scrollerLine {
  position: relative;
  height: 1px;
  width: 45px;
  overflow: hidden;
  span {
    position: absolute;
    height: 1px;
    width:100%;
    background-color:$white;
  }
}

.banderole-nav-right, .banderole-next  {
  .arrow {
      animation: arrowLeft 3s infinite;
      animation-iteration-count: infinite;
      -webkit-animation: arrowLeft 3s infinite;  /* Chrome, Safari, Opera */
      -webkit-animation-iteration-count: infinite;  /* Chrome, Safari, Opera */
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
  }
}

.banderole-nav-left {
  .arrow {
      animation: arrowRight 3s infinite;
      animation-iteration-count: infinite;
      -webkit-animation: arrowRight 3s infinite;  /* Chrome, Safari, Opera */
      -webkit-animation-iteration-count: infinite;  /* Chrome, Safari, Opera */
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
  }
}

@keyframes arrowLeft {

  0%, 10%, 50%, 90%, 100% {
    transform: translateX(0);
  }
	30% {
    transform: translateX(-10px);
  }
	70% {
    transform: translateX(-10px);
  }
}

@keyframes arrowRight {
  0%, 10%, 50%, 90%, 100% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(10px);
  }
  70% {
    transform: translateX(10px);
  }
}

.banderole.scrolled {
    .banderole-nav-right{
        left: auto;
        right: 0;
    }
    .banderole-nav-left{
        left: 0;
        right: auto;
    }
    .banderole-nav-right, .banderole-nav-left {
        /*width: 25px;*/
        display: block;
        bottom: auto;
        top: 50%;
        transform: translate3d(0,-50%,0);
        height:10%;
        height: 45px; /* references for the moment to burger-icon */
        li{
            display:inline;
            height:auto;
        }
    }
}

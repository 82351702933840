/*
    Created on : 23.04.2016, 11:32:11
    Author     : Florian
    Outsource Pics for faster change and clear mixins.
*/ 
$i_url:"../Bilder";
@mixin bg($id,  $z, $src, $pos, $size) {
    ##{$id}.load    {
        @if($src!="") {background-image: url("#{$i_url}/#{$src}.jpg");}
        @if $z {z-index: #{$z};};
        @if $pos {background-position:#{$pos};}
        @if $size {background-size:#{$size};}
    }
}


/*  All BGs that got .load after being preloaded 
    "id" 
    src /wo .jpg // optional if not img-name=id
    BG-Pos // optional if not declared: inherit!
    z-index 
*/
    $backgrounds:(
        "packages-main" "" "50% 80%" "0" "",
        "loader-bg" "loader" "50% 80%" "" "cover"
    );
.banderole-img.banderole4-img1{
    background-image: url("../Bilder/banderole4_1.jpg");
}
@each $id, $src, $pos, $z, $size in $backgrounds {
    // @if $src==null {$src:$id}
    @include bg($id, $z, $src, $pos, $size);
}
.banderole-img.banderole5-img1{
    background-position: 34% 50%;
}
 @include bp(imac){
         .banderole-img.banderole1-img1{background-position:50% 100%}
    }
@include bp(ipadV){
    .banderole4-img1{
        /*        background-image:url("../Bilder/loader.jpg") !important;*/
    }
    .banderole-img.banderole1-img7{
            background-position: 84% 0%;
    }
    
    .banderole-img.banderole1-img8{
            background-position: center 50%;
    }
    
    .banderole-img.banderole1-img9{
            background-position: 40% 0%;
    }
    .banderole-img.banderole2-img4{
            background-position: 52% 100%;
    }
    .banderole-img.banderole3-img3{
            background-position: 57% 0%;
    }
    .banderole-img.banderole3-img4{
            background-position: 53% 0%;
    }
    
    .banderole-img.banderole1-img1:before{
            background-position: 50% 70%;
    }
    .banderole-img.banderole5-img1:before{
            background-position: 35% 50%;
    }
    .banderole-img.banderole5-img2{
            background-position: 38% 0%;
    }  
}
.mobileOnly.cssPics.loaded{
    .start-gastgeber-mob .banderole-img{
        background-image: url("../Bilder/banderole4_2.jpg");
    }
    .start-antikhaus-mob .banderole-img{
        background-image: url("../Bilder/banderole4_3.jpg");
        background-position: center 80%;
    }
    .start-golfmuseum-mob .banderole-img{
        background-image: url("../Bilder/banderole4_4.jpg");
    }
    .presse-mob .banderole-img{
        background-image: url("../Bilder/banderole6_1.jpg"); 
    }
    .service-main-mob .banderole-img{
        background-image: url("../Bilder/banderole5_1.jpg"); 
    }
    .service-packages-mob .banderole-img{
        background-image: url("../Bilder/banderole5_2.jpg"); 
    }
}

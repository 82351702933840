$menu-bg: $grey;
$menu-color: $white;
$burger-size: 40px;
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
#mobile-menu-con{
    display:none;
}
.burger.burger-vertical {
    position:absolute;
    width: $burger-size;
    height: $burger-size;
    z-index:2000;
    transform:translate3d(0,0,2000px);
    top: $outerMargin;
    -webkit-tap-highlight-color:transparent;
    &,& *{-webkit-user-select: none;}
    @include burger-vertical($burger-size,1px, $white,$warmGrey);
    left:$outerMargin - $burger-size * 0.38;
    &.active {
        // -px because of small padding (probably because of rotate)
        left: $outerMargin - 2px;
        top: $outerMargin - 5px;
        @include bp(mobileV){
            .n1, .n2{
                background-color: white;
            }
        }
        @include bp(mobileH){
            .n1, .n2{
                background-color: white;
            }
        }
    }
    &.loading {
        div{
            margin-left:50%;
        }
        .n1{
            animation: rotate 1500ms infinite linear;
        }
        .n2{
            animation: rotate 3000ms infinite linear backwards;
        }
    }
    @include bp(mobileV){
        position: absolute;
        width: $burger-size/1.5;
        height: $burger-size/1.5;
        z-index:2000;
        top:$mobileOuterMargin;
        @include burger-vertical($burger-size/1.5,1px, $grey,$warmGrey); // use grey burger for mobile
        left:$mobileOuterMargin - $burger-size/1.5 * 0.38;
    }
    @include bp(mobileH){
        position: absolute;
        width: $burger-size/1.5;
        height: $burger-size/1.5;
        z-index:2000;
        top:$mobileOuterMargin;
        @include burger-vertical($burger-size/1.5,1px, $grey,$warmGrey); // use grey burger for mobile
        left:$mobileOuterMargin - $burger-size/1.5 * 0.38;
    }
}
#menu-con, #mobile-menu-con {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index:20;
    transition: transform 1.5s ease, background-color 1.5s ease, color 1.5s ease;
    transform:translate3d(-100%,0,20px);
    
    border-color: transparent;
    background-color: $menu-bg;
    color: $menu-color;
    
    @include bp(ipadV){
        min-width:35%;
    }
    @include bp(mobileV){
        min-width:100%;
    }
    @include bp(mobileH){
        min-width:100%;
    }
    &.menu-active {
        background-color: fade-out($grey, 0.2);
        transform:translate3d(0,0,20px);
        left: 0;
    }
    #ul-wrapper {
        height: 100%;
    }
    ul {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        /*margin-left&right like burger!*/
        margin: 0 2.8em;
        
        @include bp(mobileH){
            height: auto;
            column-count: 2; // two column list
            column-gap: 70px; // gap between columns
            /*margin-top: 20%;*/
            margin-top: 31px;
    position: absolute;
        }
        @media (max-width: 550px){
            transform: none;
            top: 0;
            margin-top: 90px;
        }
    }
    li {
        position: relative;
        box-sizing: border-box; 
        height: auto;
        font-size: $defFontSize;
        line-height: 2.2em;
        display: block;
        letter-spacing:0.15em;
        a {
            font-weight: normal;
        }
        &:hover > a {
            color: $warmGrey;
            .arrow, .arrow:after {
                border-color: $warmGrey;
            }
        }
        &.active {
            .arrow, .arrow:after {
                color: $warmGrey;
                border-color: $warmGrey;
            }
        }
        &#l_buchen, &#l_social > div{
            padding-top: 2em;
        }
        &#l_social > div {
          a:hover {
            color: $warmGrey;
          }
        }
    }
    .arrow {
        /*font-size: 1.75rem;*/
        /*display:inline-block;*/
        position: absolute;
        /*height: 1em;*/
        /*width: 1em;*/
        /*line-height: 0.75em;*/
        /*text-align: center;*/
        /*vertical-align: middle;*/
        border-radius: 50%;
        border: 1px solid white;
        top: 50%;
        transition: transform 500ms linear, border-color 500ms linear;
        transform: translateY(-50%);
        &.arrow-up {
            transform: translateY(-50%) rotate(-90deg);
        }
        &.arrow-right {
            transform: translateY(-50%);
        }
        &.arrow-left {
            transform: translateY(-50%) rotate(-180deg);
        }
        &.arrow-down {
            transform: translateY(-50%) rotate(90deg);
        }
    }
    .li-text {
        margin-left: 2.5rem;
    }
}


$footer-height:250px; // Later: height:auto or in em relative to footer font-size
$footer-bg: $grey; // Later: height:auto or in em relative to footer font-size

#kontakt-main,#kontakt-mob{
    height: 15em;
    height: auto;
    color:#fff;
    @include bp(mobileV){
        height: 50%;
    }
    @include bp(mobileH){
        height: 60%;
    }
}
footer {
    font-size:0.75rem;
    width: 100%;
    height:100%;
    background-color: $footer-bg;
    box-sizing: border-box;
    text-align: center;
    padding: 3em;
    line-height: 1.8em;
    // transform: translateY(-99.5%); // bei 100% bleibt blitzer unten
    p{
        hyphens: none;
        width: 100%;
/*        position:absolute;
        top:50%;
        left:50%;
        transform: translate3d(-50%,-50%,0);*/
    }
    span.gara {
            @include fontGaramondRegula;
    }
}
/*Ende Buchung*/
#nl-form {
    display: none;
    &.show {
        display: block;
    }
}
 .formular.show{
    display:none;
}
#nl-text{display:none;}
input,
#nl-text,
#saving {
    @include fontMain;
    width:100%;
    max-width:32em;
    box-sizing: border-box;
    padding:0.8em;
    margin:4px 0;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 0.15em;
    font-weight: 300;
    @media (min-width: 677px) {
        width: 65%;
    }
}

#nl-form .checkbox--wrapper {
  @include fontMain;
  width:100%;
  max-width:32em;
  margin:4px auto;
  text-align: left;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  @media (min-width: 677px) {
      width: 65%;
  }
  > input, > label {
    display: inline-block;
  }
  > input {
    width: 25px;
  }
  label {
    width: 90%;
    a {
      text-transform: none;
    }
  }
}

input{
    // border:solid 1px $cool;
    border-radius: 0;
    border:0;

}
.gogogo{
    width: 45%;
    margin: 24px 27.5%;
    background-color: #b7ada5 !important;
    color:white;
    border:0;
    border-radius: 0;
    -webkit-appearance: none;
    @media (max-width: 450px) {
        margin: 24px 20%;
        margin-left:0; /*evtl auch global*/
        width: 60%;
    }
}
#saving,#nl-text{
    display:none;
    padding-left:0;
    padding-right:0;
    &.show{
        display:inline-block;
    }

}

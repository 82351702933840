$defFontSize: 0.75rem;
$defLineHeight: 1.3rem;
$defLetterSpacing: 0.09em;
//$defLetterSpacing: 0.06em;


@each $font in 
thinitalic 100 italic,
thin 100 normal,
light 300 normal,
bold 700 normal {
    @font-face {
        font-family: 'titillium';
        font-style: nth($font, 3);
        font-weight: nth($font, 2);
        src: url('../font/titillium-#{nth($font,1)}-webfont.eot');
        src: url('../font/titillium-#{nth($font,1)}-webfont.eot?#iefix') format('embedded-opentype'),
        url('../font/titillium-#{nth($font,1)}-webfont.woff2') format('woff2'),
        url('../font/titillium-#{nth($font,1)}-webfont.woff') format('woff'),
        url('../font/titillium-#{nth($font,1)}-webfont.ttf') format('truetype');
    }
}
@mixin fontMain {
    font-family: 'titillium', Titillium, sans-serif;
}

@mixin fontStd {
    @include fontMain;
    font-weight: 400;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
}

@mixin fontPage {
    @include fontStd;
    font-size: 36px;
    letter-spacing: 0.09em;
    line-height:1.2;
    @media screen and (max-height:800px) and (max-width: $break-ipadH) {
        font-size: 32px;
    }
    @media screen and (max-width: $break-small) {
        font-size: 28px;
    }
}

@mixin fontArtH1 {
    @include fontStd;
    font-size: 36px;
    letter-spacing: 0.09em;
    @media screen and (max-height:800px) and (max-width: $break-ipadH) {
        font-size: 32px;
    }
    @media screen and (max-width: $break-small) {
        font-size: 28px;
    }
    
    letter-spacing: 0.035em;
    font-weight: 700;
    color: $blauText;
    line-height: 1.15em;
    @media screen and (max-height:800px) and (max-width: $break-ipadH) {
        font-size: 40px;
    }
    @media screen and (max-width: $break-small) {
        font-size: 30px;
    }
}

@mixin fontMenu {
    @include fontStd;
}

@mixin fontMenuBig {
    @include fontMenu;
    font-size: 75px;
    letter-spacing: 0.07em
}

@mixin fontMenuSmall {
    @include fontMenu;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.08em;
}

@mixin fontMenuInput {
    @include fontMenu;
    font-size: 12px;
    font-style: normal;
    text-transform: none;
    letter-spacing: 0.06em;
    font-weight: 300;
    color: $cyan;
}

@mixin fontArtH2 {
    @include fontMenuSmall;
}

@mixin fontArtFluss {
    @include fontMenuInput;
    color: $blauText;
    line-height: 1.5em;
    font-weight: 100;
}


//Garamond start
@mixin fontGaramondRegula {
	font-family: "AdobeGaramondW01-Regula";
}

@font-face {
font-family:"AdobeGaramondW01-Regula";
src:url("../fonts/73fe293c-69da-4927-80ce-773ee1a1879c.eot?#iefix");
src:url("../fonts/73fe293c-69da-4927-80ce-773ee1a1879c.eot?#iefix") format("eot"),url("../fonts/d5ca2f36-723d-4882-8658-9746fb0eea72.woff2") format("woff2"),url("../fonts/859018fa-3c03-484b-8774-97bd6548d48d.woff") format("woff"),url("../fonts/abb87b70-21d4-4ac2-bf9f-41548a67e564.ttf") format("truetype"),url("../fonts/6e4e2084-ff69-479e-b179-92d90deb7885.svg#6e4e2084-ff69-479e-b179-92d90deb7885") format("svg");
}
@font-face {
font-family:"Adobe Garamond W01 It";
src:url("../fonts/cc631089-fa2c-4288-baf8-2e744de1a554.eot?#iefix");
src:url("../fonts/cc631089-fa2c-4288-baf8-2e744de1a554.eot?#iefix") format("eot"),url("../fonts/68e92036-cf88-44c3-8061-e7d30fc9d1c4.woff2") format("woff2"),url("../fonts/bb497139-4ed5-41d1-905c-46d29a1b30f8.woff") format("woff"),url("../fonts/80bceb3f-535e-478f-95eb-db6597482771.ttf") format("truetype"),url("../fonts/63e15210-781f-4eae-b157-1a28f02f1cfe.svg#63e15210-781f-4eae-b157-1a28f02f1cfe") format("svg");
}
@font-face {
font-family:"Adobe Garamond W01 SmBd";
src:url("../fonts/0d0bd309-6c34-40a5-9a6c-9760379b7c5e.eot?#iefix");
src:url("../fonts/0d0bd309-6c34-40a5-9a6c-9760379b7c5e.eot?#iefix") format("eot"),url("../fonts/74f8f324-5678-419e-a062-18a9b7ce2cd8.woff2") format("woff2"),url("../fonts/7dc507e2-738a-4a3d-9253-31a1f36bf337.woff") format("woff"),url("../fonts/37d87f5a-c625-4343-a3d8-00c73e1bc91a.ttf") format("truetype"),url("../fonts/498f83f0-7a41-4bfb-82d9-e7ced50ad6dc.svg#498f83f0-7a41-4bfb-82d9-e7ced50ad6dc") format("svg");
}
@font-face {
font-family:"AdobeGaramondW01-SmBdIt";
src:url("../fonts/6ab8ca00-cb3c-4650-9898-8bd7549e4bba.eot?#iefix");
src:url("../fonts/6ab8ca00-cb3c-4650-9898-8bd7549e4bba.eot?#iefix") format("eot"),url("../fonts/67966868-1c07-4861-84a1-aff5f6d52915.woff2") format("woff2"),url("../fonts/f57c0124-5531-42de-8161-da69918f789c.woff") format("woff"),url("../fonts/bb927941-6c69-42fa-835e-85beae1f8365.ttf") format("truetype"),url("../fonts/8ef94dbb-5086-4bdd-be4e-60dc74748264.svg#8ef94dbb-5086-4bdd-be4e-60dc74748264") format("svg");
}
@font-face {
font-family:"Adobe Garamond W01 Bd";
src:url("../fonts/a5240191-4466-40a5-9858-50ca14584e4a.eot?#iefix");
src:url("../fonts/a5240191-4466-40a5-9858-50ca14584e4a.eot?#iefix") format("eot"),url("../fonts/f81fa4ca-48c0-496c-a54f-5e1f69bc54a1.woff2") format("woff2"),url("../fonts/cd40a899-1f96-413c-9d0b-725d91328946.woff") format("woff"),url("../fonts/f3b3a415-75b2-4241-9867-da4e137d5dee.ttf") format("truetype"),url("../fonts/f4c5fec8-4f38-4edc-b25b-295a90bf8e57.svg#f4c5fec8-4f38-4edc-b25b-295a90bf8e57") format("svg");
}
@font-face {
font-family:"AdobeGaramondW01-BdIt";
src:url("../fonts/7b962fbf-c081-46ab-858f-dee2779c4fa4.eot?#iefix");
src:url("../fonts/7b962fbf-c081-46ab-858f-dee2779c4fa4.eot?#iefix") format("eot"),url("../fonts/4f3b4e5d-e634-4c01-8a14-690e44f8ef58.woff2") format("woff2"),url("../fonts/f8ee3845-fc85-4f8b-9bee-9eac5fccb0f3.woff") format("woff"),url("../fonts/a33b7202-9081-4ce7-ad4d-528996891012.ttf") format("truetype"),url("../fonts/d2e699fe-6a32-45fd-b5a6-30b435bd8bca.svg#d2e699fe-6a32-45fd-b5a6-30b435bd8bca") format("svg");
}

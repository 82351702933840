.banderole {
        overflow:auto;
        /*Doesnt work on Iphone*/
    	overflow-x: scroll;
	overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
	padding-bottom: 10000px; // move scrollbar down
	white-space: nowrap;
	user-select: none;
        font-size:0;
/*      Til now unnecess.
        max-height:100%;
        max-height:100vh;*/

}
.banderole-img-wrapper {
    	display: inline;
	letter-spacing: -1px; //fix for safari+chrome
        font-size:0;
}
.banderole-img {
    height: 100%;
    height: 100vh;
    min-width: 100%;
    box-sizing: border-box;
    border:none;
    &:not(.first){
        border-right:solid 1px white;
    }
    display: inline-block;
    background: no-repeat center center/cover;
    @include bp(ipadV){
          min-width:200%;
    }

    &.with-aside {
        min-width:69%;
        @media(max-width: 1280px){
            min-width:100%;
            #start &,&.first{
                min-width: 65%;
            }
        }
        @include bp(smallWide){
            #start &,&.first{
                min-width: 60%;
            }    
            &.banderole4-img1{
                background-position: 100% 86%;
            }
        }
        @include bp(ipadV){
            &:not(.first){
                min-width: 200%;
            }
            &#service-packages{
                min-width:100%;
            }
            &.banderole4-img1{
                background-position: 100% 86%;
            }
            #start &,&.first{
                min-width: 100%;
            }
        }
        @include bp(ipadH){
            #start &,&.first{
                min-width: 60%;
            }
            &.banderole4-img1{
                background-position: 100% 86%;
            }
        }
        @include bp(mobileV){
            min-width:100%;
            #start &,&.first{
                min-width: 100%;
            }
        }
        @include bp(mobileH){
            min-width:100%;
            #start &,&.first{
                min-width: 100%;
            }
        }
    }
    @include bp(mobileV){
        min-width: 200%;
        &.with-aside{
            min-width:200%;
        }
    }
    @include bp(mobileH){
        min-width: 100%;
        #start &.with-aside{
            min-width:60%;
            &.first{
                width:100%;
            }
        }
    }

    &.size2 {
        min-width:61%;
    }
    &.first{
        @include bp(ipadV){
            min-width:100vw;
        }
    }
    &::before{
/*        opacity: 0;*/
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: no-repeat center center/cover;
        /*transition: opacity 1s linear;*/
    }
    &.img-honey::before{
        /*opacity: 1;*/
        background-image: url("../Bilder/honeymoon_web.jpg");
        background-position: 50% 80%;
    }
    &.img-golf::before{
        /*opacity: 1;*/
        background-image: url("../Bilder/thiergarten.jpg");
        background-position: 50% 59%;
    }
    // &.img-start::before{
    //     opacity: 1;
    //     background-image: url("http://lorempixel.com/g/1000/800/nature/starthilfe/");
    // }
}
/* Test for iPhone - different way of attaching Pics */
.nojs,.cssPics.loaded{
    @for $j from 1 through 6 {
        @for $i from 1 through 9 {
            .banderole#{$j}-img#{$i} {
                background-image: url("../Bilder/banderole"+$j+"_"+$i+".jpg");
            }
        }
    }
}

@for $j from 1 through 6 {
    @for $i from 1 through 9 {
        .banderole#{$j}-img#{$i}.oneCssLoaded {
            background-image: url("../Bilder/banderole"+$j+"_"+$i+".jpg");
        }
    }
}
@include bp(ipadV){
    .banderole4-img1:before{
        background-position: 100% 50%;
    }
}

@include bp(ipadH){
    .banderole1-img1 {
        background-position: 70% 50%;
    }
}

@include bp(mobileH){
    .banderole4-img1.with-aside, .banderole4-img1:before{
        min-width: 50%;
    }
}
.banderole4-img1{
    /*background-position: center 86%;*/
}
.banderole4-img3{
    background-position:center 100%;
}
.banderole5-img2,.id-packages ul,.id-packages header{
    -webkit-backface-visibility: hidden;
}
.banderole5-img2{
    background-position: 0% 60%;
}
.banderole3-img7 {
    background-position-y: 100%;
}
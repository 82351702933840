@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* resets for different browsers and sets standards */
@font-face {
  font-family: 'fontello';
  src: url("../font/fontello.eot?39861083");
  src: url("../font/fontello.eot?39861083#iefix") format("embedded-opentype"), url("../font/fontello.woff?39861083") format("woff"), url("../font/fontello.ttf?39861083") format("truetype"), url("../font/fontello.svg?39861083#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?39861083#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-facebook:before {
  content: '\e800'; }

/* '' */
.icon-twitter:before {
  content: '\e801'; }

/* '' */
/* smaller font-awesome TODO: Change icons to whats actually needed */
/* different mixins TODO: Kill whats depr */
/* color variables */
@font-face {
  font-family: 'titillium';
  font-style: italic;
  font-weight: 100;
  src: url("../font/titillium-thinitalic-webfont.eot");
  src: url("../font/titillium-thinitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../font/titillium-thinitalic-webfont.woff2") format("woff2"), url("../font/titillium-thinitalic-webfont.woff") format("woff"), url("../font/titillium-thinitalic-webfont.ttf") format("truetype"); }

@font-face {
  font-family: 'titillium';
  font-style: normal;
  font-weight: 100;
  src: url("../font/titillium-thin-webfont.eot");
  src: url("../font/titillium-thin-webfont.eot?#iefix") format("embedded-opentype"), url("../font/titillium-thin-webfont.woff2") format("woff2"), url("../font/titillium-thin-webfont.woff") format("woff"), url("../font/titillium-thin-webfont.ttf") format("truetype"); }

@font-face {
  font-family: 'titillium';
  font-style: normal;
  font-weight: 300;
  src: url("../font/titillium-light-webfont.eot");
  src: url("../font/titillium-light-webfont.eot?#iefix") format("embedded-opentype"), url("../font/titillium-light-webfont.woff2") format("woff2"), url("../font/titillium-light-webfont.woff") format("woff"), url("../font/titillium-light-webfont.ttf") format("truetype"); }

@font-face {
  font-family: 'titillium';
  font-style: normal;
  font-weight: 700;
  src: url("../font/titillium-bold-webfont.eot");
  src: url("../font/titillium-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../font/titillium-bold-webfont.woff2") format("woff2"), url("../font/titillium-bold-webfont.woff") format("woff"), url("../font/titillium-bold-webfont.ttf") format("truetype"); }

@font-face {
  font-family: "AdobeGaramondW01-Regula";
  src: url("../fonts/73fe293c-69da-4927-80ce-773ee1a1879c.eot?#iefix");
  src: url("../fonts/73fe293c-69da-4927-80ce-773ee1a1879c.eot?#iefix") format("eot"), url("../fonts/d5ca2f36-723d-4882-8658-9746fb0eea72.woff2") format("woff2"), url("../fonts/859018fa-3c03-484b-8774-97bd6548d48d.woff") format("woff"), url("../fonts/abb87b70-21d4-4ac2-bf9f-41548a67e564.ttf") format("truetype"), url("../fonts/6e4e2084-ff69-479e-b179-92d90deb7885.svg#6e4e2084-ff69-479e-b179-92d90deb7885") format("svg"); }

@font-face {
  font-family: "Adobe Garamond W01 It";
  src: url("../fonts/cc631089-fa2c-4288-baf8-2e744de1a554.eot?#iefix");
  src: url("../fonts/cc631089-fa2c-4288-baf8-2e744de1a554.eot?#iefix") format("eot"), url("../fonts/68e92036-cf88-44c3-8061-e7d30fc9d1c4.woff2") format("woff2"), url("../fonts/bb497139-4ed5-41d1-905c-46d29a1b30f8.woff") format("woff"), url("../fonts/80bceb3f-535e-478f-95eb-db6597482771.ttf") format("truetype"), url("../fonts/63e15210-781f-4eae-b157-1a28f02f1cfe.svg#63e15210-781f-4eae-b157-1a28f02f1cfe") format("svg"); }

@font-face {
  font-family: "Adobe Garamond W01 SmBd";
  src: url("../fonts/0d0bd309-6c34-40a5-9a6c-9760379b7c5e.eot?#iefix");
  src: url("../fonts/0d0bd309-6c34-40a5-9a6c-9760379b7c5e.eot?#iefix") format("eot"), url("../fonts/74f8f324-5678-419e-a062-18a9b7ce2cd8.woff2") format("woff2"), url("../fonts/7dc507e2-738a-4a3d-9253-31a1f36bf337.woff") format("woff"), url("../fonts/37d87f5a-c625-4343-a3d8-00c73e1bc91a.ttf") format("truetype"), url("../fonts/498f83f0-7a41-4bfb-82d9-e7ced50ad6dc.svg#498f83f0-7a41-4bfb-82d9-e7ced50ad6dc") format("svg"); }

@font-face {
  font-family: "AdobeGaramondW01-SmBdIt";
  src: url("../fonts/6ab8ca00-cb3c-4650-9898-8bd7549e4bba.eot?#iefix");
  src: url("../fonts/6ab8ca00-cb3c-4650-9898-8bd7549e4bba.eot?#iefix") format("eot"), url("../fonts/67966868-1c07-4861-84a1-aff5f6d52915.woff2") format("woff2"), url("../fonts/f57c0124-5531-42de-8161-da69918f789c.woff") format("woff"), url("../fonts/bb927941-6c69-42fa-835e-85beae1f8365.ttf") format("truetype"), url("../fonts/8ef94dbb-5086-4bdd-be4e-60dc74748264.svg#8ef94dbb-5086-4bdd-be4e-60dc74748264") format("svg"); }

@font-face {
  font-family: "Adobe Garamond W01 Bd";
  src: url("../fonts/a5240191-4466-40a5-9858-50ca14584e4a.eot?#iefix");
  src: url("../fonts/a5240191-4466-40a5-9858-50ca14584e4a.eot?#iefix") format("eot"), url("../fonts/f81fa4ca-48c0-496c-a54f-5e1f69bc54a1.woff2") format("woff2"), url("../fonts/cd40a899-1f96-413c-9d0b-725d91328946.woff") format("woff"), url("../fonts/f3b3a415-75b2-4241-9867-da4e137d5dee.ttf") format("truetype"), url("../fonts/f4c5fec8-4f38-4edc-b25b-295a90bf8e57.svg#f4c5fec8-4f38-4edc-b25b-295a90bf8e57") format("svg"); }

@font-face {
  font-family: "AdobeGaramondW01-BdIt";
  src: url("../fonts/7b962fbf-c081-46ab-858f-dee2779c4fa4.eot?#iefix");
  src: url("../fonts/7b962fbf-c081-46ab-858f-dee2779c4fa4.eot?#iefix") format("eot"), url("../fonts/4f3b4e5d-e634-4c01-8a14-690e44f8ef58.woff2") format("woff2"), url("../fonts/f8ee3845-fc85-4f8b-9bee-9eac5fccb0f3.woff") format("woff"), url("../fonts/a33b7202-9081-4ce7-ad4d-528996891012.ttf") format("truetype"), url("../fonts/d2e699fe-6a32-45fd-b5a6-30b435bd8bca.svg#d2e699fe-6a32-45fd-b5a6-30b435bd8bca") format("svg"); }

/* font imports, settings and mixins TODO: Kill whats depr */
/*
    Created on : 23.04.2016, 11:32:11
    Author     : Florian
    Outsource Pics for faster change and clear mixins.
*/
/*  All BGs that got .load after being preloaded 
    "id" 
    src /wo .jpg // optional if not img-name=id
    BG-Pos // optional if not declared: inherit!
    z-index 
*/
.banderole-img.banderole4-img1 {
  background-image: url("../Bilder/banderole4_1.jpg"); }

#packages-main.load {
  z-index: 0;
  background-position: 50% 80%; }

#loader-bg.load {
  background-image: url("../Bilder/loader.jpg");
  background-position: 50% 80%;
  background-size: cover; }

.banderole-img.banderole5-img1 {
  background-position: 34% 50%; }

@media (min-width: 1500px) {
  .banderole-img.banderole1-img1 {
    background-position: 50% 100%; } }

@media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
  .banderole4-img1 {
    /*        background-image:url("../Bilder/loader.jpg") !important;*/ }
  .banderole-img.banderole1-img7 {
    background-position: 84% 0%; }
  .banderole-img.banderole1-img8 {
    background-position: center 50%; }
  .banderole-img.banderole1-img9 {
    background-position: 40% 0%; }
  .banderole-img.banderole2-img4 {
    background-position: 52% 100%; }
  .banderole-img.banderole3-img3 {
    background-position: 57% 0%; }
  .banderole-img.banderole3-img4 {
    background-position: 53% 0%; }
  .banderole-img.banderole1-img1:before {
    background-position: 50% 70%; }
  .banderole-img.banderole5-img1:before {
    background-position: 35% 50%; }
  .banderole-img.banderole5-img2 {
    background-position: 38% 0%; } }

.mobileOnly.cssPics.loaded .start-gastgeber-mob .banderole-img {
  background-image: url("../Bilder/banderole4_2.jpg"); }

.mobileOnly.cssPics.loaded .start-antikhaus-mob .banderole-img {
  background-image: url("../Bilder/banderole4_3.jpg");
  background-position: center 80%; }

.mobileOnly.cssPics.loaded .start-golfmuseum-mob .banderole-img {
  background-image: url("../Bilder/banderole4_4.jpg"); }

.mobileOnly.cssPics.loaded .presse-mob .banderole-img {
  background-image: url("../Bilder/banderole6_1.jpg"); }

.mobileOnly.cssPics.loaded .service-main-mob .banderole-img {
  background-image: url("../Bilder/banderole5_1.jpg"); }

.mobileOnly.cssPics.loaded .service-packages-mob .banderole-img {
  background-image: url("../Bilder/banderole5_2.jpg"); }

/* bg settings and mixins TODO: Map for banderole Pics */
@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

#mobile-menu-con {
  display: none; }

.burger.burger-vertical {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 2000;
  transform: translate3d(0, 0, 2000px);
  top: 35px;
  -webkit-tap-highlight-color: transparent;
  transition: left 1s 0s,top 1s 0s, height 1s 0s;
  left: 19.8px; }
  .burger.burger-vertical, .burger.burger-vertical * {
    -webkit-user-select: none; }
  .burger.burger-vertical div {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: white;
    transition: transform 500ms 0s, margin 500ms 500ms,  height 1s 0s, background-color 1s 0s;
    transform: translateX(50%); }
  .burger.burger-vertical .n1 {
    margin-left: 38%; }
  .burger.burger-vertical .n2 {
    margin-left: 62%; }
  .burger.burger-vertical.active {
    height: 56.4px; }
    .burger.burger-vertical.active div {
      transition: transform 500ms 500ms, margin 500ms 0s, height 1s 0s, background-color 1s 0s; }
    .burger.burger-vertical.active .n1 {
      margin-left: 50%;
      transform: translateX(50%) rotate(45deg); }
    .burger.burger-vertical.active .n2 {
      margin-left: 50%;
      transform: rotate(-45deg); }
  .burger.burger-vertical.active {
    left: 33px;
    top: 30px; }
    @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
      .burger.burger-vertical.active .n1, .burger.burger-vertical.active .n2 {
        background-color: white; } }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      .burger.burger-vertical.active .n1, .burger.burger-vertical.active .n2 {
        background-color: white; } }
  .burger.burger-vertical.loading div {
    margin-left: 50%; }
  .burger.burger-vertical.loading .n1 {
    animation: rotate 1500ms infinite linear; }
  .burger.burger-vertical.loading .n2 {
    animation: rotate 3000ms infinite linear backwards; }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .burger.burger-vertical {
      position: absolute;
      width: 26.66667px;
      height: 26.66667px;
      z-index: 2000;
      top: 15px;
      transition: left 1s 0s,top 1s 0s, height 1s 0s;
      left: 4.86667px; }
      .burger.burger-vertical div {
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #474a50;
        transition: transform 500ms 0s, margin 500ms 500ms,  height 1s 0s, background-color 1s 0s;
        transform: translateX(50%); }
      .burger.burger-vertical .n1 {
        margin-left: 38%; }
      .burger.burger-vertical .n2 {
        margin-left: 62%; }
      .burger.burger-vertical.active {
        height: 37.6px; }
        .burger.burger-vertical.active div {
          transition: transform 500ms 500ms, margin 500ms 0s, height 1s 0s, background-color 1s 0s; }
        .burger.burger-vertical.active .n1 {
          margin-left: 50%;
          transform: translateX(50%) rotate(45deg); }
        .burger.burger-vertical.active .n2 {
          margin-left: 50%;
          transform: rotate(-45deg); } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    .burger.burger-vertical {
      position: absolute;
      width: 26.66667px;
      height: 26.66667px;
      z-index: 2000;
      top: 15px;
      transition: left 1s 0s,top 1s 0s, height 1s 0s;
      left: 4.86667px; }
      .burger.burger-vertical div {
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #474a50;
        transition: transform 500ms 0s, margin 500ms 500ms,  height 1s 0s, background-color 1s 0s;
        transform: translateX(50%); }
      .burger.burger-vertical .n1 {
        margin-left: 38%; }
      .burger.burger-vertical .n2 {
        margin-left: 62%; }
      .burger.burger-vertical.active {
        height: 37.6px; }
        .burger.burger-vertical.active div {
          transition: transform 500ms 500ms, margin 500ms 0s, height 1s 0s, background-color 1s 0s; }
        .burger.burger-vertical.active .n1 {
          margin-left: 50%;
          transform: translateX(50%) rotate(45deg); }
        .burger.burger-vertical.active .n2 {
          margin-left: 50%;
          transform: rotate(-45deg); } }

#menu-con, #mobile-menu-con {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
  transition: transform 1.5s ease, background-color 1.5s ease, color 1.5s ease;
  transform: translate3d(-100%, 0, 20px);
  border-color: transparent;
  background-color: #474a50;
  color: white; }
  @media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
    #menu-con, #mobile-menu-con {
      min-width: 35%; } }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    #menu-con, #mobile-menu-con {
      min-width: 100%; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    #menu-con, #mobile-menu-con {
      min-width: 100%; } }
  #menu-con.menu-active, #mobile-menu-con.menu-active {
    background-color: rgba(71, 74, 80, 0.8);
    transform: translate3d(0, 0, 20px);
    left: 0; }
  #menu-con #ul-wrapper, #mobile-menu-con #ul-wrapper {
    height: 100%; }
  #menu-con ul, #mobile-menu-con ul {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    /*margin-left&right like burger!*/
    margin: 0 2.8em; }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      #menu-con ul, #mobile-menu-con ul {
        height: auto;
        column-count: 2;
        column-gap: 70px;
        /*margin-top: 20%;*/
        margin-top: 31px;
        position: absolute; } }
    @media (max-width: 550px) {
      #menu-con ul, #mobile-menu-con ul {
        transform: none;
        top: 0;
        margin-top: 90px; } }
  #menu-con li, #mobile-menu-con li {
    position: relative;
    box-sizing: border-box;
    height: auto;
    font-size: 0.75rem;
    line-height: 2.2em;
    display: block;
    letter-spacing: 0.15em; }
    #menu-con li a, #mobile-menu-con li a {
      font-weight: normal; }
    #menu-con li:hover > a, #mobile-menu-con li:hover > a {
      color: #b7ada5; }
      #menu-con li:hover > a .arrow, #menu-con li:hover > a .arrow:after, #mobile-menu-con li:hover > a .arrow, #mobile-menu-con li:hover > a .arrow:after {
        border-color: #b7ada5; }
    #menu-con li.active .arrow, #menu-con li.active .arrow:after, #mobile-menu-con li.active .arrow, #mobile-menu-con li.active .arrow:after {
      color: #b7ada5;
      border-color: #b7ada5; }
    #menu-con li#l_buchen, #menu-con li#l_social > div, #mobile-menu-con li#l_buchen, #mobile-menu-con li#l_social > div {
      padding-top: 2em; }
    #menu-con li#l_social > div a:hover, #mobile-menu-con li#l_social > div a:hover {
      color: #b7ada5; }
  #menu-con .arrow, #mobile-menu-con .arrow {
    /*font-size: 1.75rem;*/
    /*display:inline-block;*/
    position: absolute;
    /*height: 1em;*/
    /*width: 1em;*/
    /*line-height: 0.75em;*/
    /*text-align: center;*/
    /*vertical-align: middle;*/
    border-radius: 50%;
    border: 1px solid white;
    top: 50%;
    transition: transform 500ms linear, border-color 500ms linear;
    transform: translateY(-50%); }
    #menu-con .arrow.arrow-up, #mobile-menu-con .arrow.arrow-up {
      transform: translateY(-50%) rotate(-90deg); }
    #menu-con .arrow.arrow-right, #mobile-menu-con .arrow.arrow-right {
      transform: translateY(-50%); }
    #menu-con .arrow.arrow-left, #mobile-menu-con .arrow.arrow-left {
      transform: translateY(-50%) rotate(-180deg); }
    #menu-con .arrow.arrow-down, #mobile-menu-con .arrow.arrow-down {
      transform: translateY(-50%) rotate(90deg); }
  #menu-con .li-text, #mobile-menu-con .li-text {
    margin-left: 2.5rem; }

/* just menu settings - TODO: kill all ui dependencies*/
table {
  width: 100%;
  color: #474a50; }

td {
  width: auto;
  padding-bottom: 2px; }
  td:last-of-type {
    text-align: right;
    width: 11em; }

.aside-top {
  /*position: absolute;*/
  top: 0;
  /*left: 50%;*/
  /*transform: translate3d(-50%, 0, 0);*/
  /*width: auto;*/
  /*height: auto;*/
  /*margin: -.5em 0 0 0;*/ }
  .aside-top ul {
    /*Optischer Randausgleich bei Icons*/
    margin-top: -.2em; }

.aside-bottom {
  /*position: absolute;*/
  bottom: 0;
  /*left: 50%;*/
  /*transform: translate3d(-50%, 0, 0);*/
  /*width: auto;*/
  /*margin-bottom: 3px;*/ }
  .aside-bottom ul {
    /*Optischer Randausgleich bei Icons*/
    margin-bottom: -.2em; }

.id-packages .simple {
  display: none; }

.banderole-aside .simple .svg-wrapper {
  margin-top: 0.3em; }

.sidemenu-wrapper, .aside-bottom, .aside-top {
  position: absolute;
  left: 50%;
  transform: perspective(1px) translate3d(-50%, 0, 0);
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  padding: 30px;
  letter-spacing: .15em; }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .sidemenu-wrapper.banderole-next, .sidemenu-wrapper.banderole-next-fixed, .aside-bottom.banderole-next, .aside-bottom.banderole-next-fixed, .aside-top.banderole-next, .aside-top.banderole-next-fixed {
      padding: 15px; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    .sidemenu-wrapper.banderole-next, .sidemenu-wrapper.banderole-next-fixed, .aside-bottom.banderole-next, .aside-bottom.banderole-next-fixed, .aside-top.banderole-next, .aside-top.banderole-next-fixed {
      padding: 15px; } }
  .sidemenu-wrapper li, .aside-bottom li, .aside-top li {
    position: relative;
    box-sizing: border-box;
    height: auto;
    display: block;
    font-size: 0.75rem;
    line-height: 2.6em;
    /*        i{
                    left: 0;
                    position: absolute;
                    top: 50%;
                    transition: border-color .25s linear;
                    &.arrow-up {
                        transform: translateY(-50%) rotate(-90deg);
                    }
                    &.arrow-right,
                    &.book {
                        transform: translateY(-50%);
                    }
                    &.arrow-down {
                        transform: translateY(-50%) rotate(90deg);
                    }
                    &.book::after {
                        border: solid 1px $grey;
                    }
                }*/ }
    .sidemenu-wrapper li a, .aside-bottom li a, .aside-top li a {
      color: #474a50;
      font-weight: normal; }
    .sidemenu-wrapper li:hover a, .aside-bottom li:hover a, .aside-top li:hover a {
      color: #b7ada5; }
      .sidemenu-wrapper li:hover a::before, .sidemenu-wrapper li:hover a::after, .aside-bottom li:hover a::before, .aside-bottom li:hover a::after, .aside-top li:hover a::before, .aside-top li:hover a::after {
        border-color: #b7ada5; }
    .sidemenu-wrapper li.active .arrow, .sidemenu-wrapper li.active .arrow::after, .sidemenu-wrapper li.active .arrow::before, .aside-bottom li.active .arrow, .aside-bottom li.active .arrow::after, .aside-bottom li.active .arrow::before, .aside-top li.active .arrow, .aside-top li.active .arrow::after, .aside-top li.active .arrow::before {
      border-color: #b7ada5;
      color: #b7ada5; }
    .sidemenu-wrapper li .svg-wrapper, .aside-bottom li .svg-wrapper, .aside-top li .svg-wrapper {
      position: absolute;
      top: 50%;
      height: 2em;
      margin-top: -1em;
      width: 2em;
      /*            &.arrow-up {
                            transform: rotate(-90deg);
                        }
                        &.arrow-down {
                            transform: rotate(90deg);
                        }*/ }
      .sidemenu-wrapper li .svg-wrapper svg, .aside-bottom li .svg-wrapper svg, .aside-top li .svg-wrapper svg {
        stroke: #474a50;
        fill: #474a50; }
      @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
        .sidemenu-wrapper li .svg-wrapper, .aside-bottom li .svg-wrapper, .aside-top li .svg-wrapper {
          height: 1.7em;
          margin-top: -0.85em;
          width: 1.7em;
          left: 0; } }

.social-buttons a, .social-buttons svg {
  display: inline-block; }

.start .sidemenu-wrapper, aside.loader .sidemenu-wrapper {
  margin-left: -.8em;
  top: 60%;
  transform: translate3d(-50%, -50%, 0); }
  @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
    .start .sidemenu-wrapper, aside.loader .sidemenu-wrapper {
      top: 62.5%; } }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .start .sidemenu-wrapper, aside.loader .sidemenu-wrapper {
      bottom: 0;
      margin: 0;
      padding: 18% 15%;
      max-width: 100%; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    .start .sidemenu-wrapper, aside.loader .sidemenu-wrapper {
      bottom: 0;
      margin: 0;
      padding: 18% 15%;
      max-width: 100%; } }

.start .social-buttons, aside.loader .social-buttons {
  margin-left: 0.1em;
  /*top:74%;*/
  top: 70%;
  clear: both;
  padding-top: 30px; }
  .start .social-buttons svg, aside.loader .social-buttons svg {
    width: 1.1rem;
    height: 1.2rem; }
  .start .social-buttons .facebook, aside.loader .social-buttons .facebook {
    width: 0.38rem;
    margin-right: 0.1em; }
  .start .social-buttons a:link,
  .start .social-buttons a:active,
  .start .social-buttons a:visited, aside.loader .social-buttons a:link,
  aside.loader .social-buttons a:active,
  aside.loader .social-buttons a:visited {
    color: #474a50; }
  .start .social-buttons a, aside.loader .social-buttons a {
    margin: 0.28em; }
  .start .social-buttons a:hover, aside.loader .social-buttons a:hover {
    color: #b7ada5; }
  @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
    .start .social-buttons, aside.loader .social-buttons {
      top: 74%; } }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .start .social-buttons, aside.loader .social-buttons {
      /*display:none;*/
      margin-left: 0px; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    .start .social-buttons, aside.loader .social-buttons {
      /*display:none;*/
      margin-left: 0px; } }
  @media (max-height: 550px) {
    .start .social-buttons, aside.loader .social-buttons {
      /*display:none;*/ } }

.start .aside-top, aside.loader .aside-top {
  margin-left: -.3em; }

.start .sidemenu-li, aside.loader .sidemenu-li {
  /*line-height: 3em;*/
  line-height: 2.2em; }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .start .sidemenu-li, aside.loader .sidemenu-li {
      /*line-height:2.5em;*/ } }

aside.loader {
  top: 0;
  right: 0;
  z-index: 11; }
  aside.loader .sidemenu-wrapper {
    margin-left: 0; }

@media (max-height: 320px) {
  .banderole-aside.aside-cta p {
    margin: 0;
    padding: 0 25px; } }

.banderole-aside.aside-cta .sidemenu-wrapper {
  margin-top: 1em; }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .banderole-aside.aside-cta .sidemenu-wrapper {
      position: relative;
      transform: none;
      left: auto;
      text-align: center; }
      .banderole-aside.aside-cta .sidemenu-wrapper ul {
        display: inline-block; } }
  @media (max-height: 320px) {
    .banderole-aside.aside-cta .sidemenu-wrapper {
      margin: 0;
      padding: 0;
      padding-top: 1em; } }

.banderole-aside.aside-cta .sidemenu-li {
  line-height: 3em; }
  .banderole-aside.aside-cta .sidemenu-li .arrow-book {
    font-size: 58%;
    margin-left: 0.78em; }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .banderole-aside.aside-cta .sidemenu-li {
      line-height: 2.8em; } }

.logo-con {
  position: absolute;
  top: 40%;
  left: 0;
  margin-top: -7em;
  width: 100%;
  box-sizing: border-box; }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .logo-con {
      margin-top: -25%;
      /*        position:relative;
                margin:0;
                padding:18% 15%;
                max-width:100%;*/ } }

.logo {
  /*margin-top: 256px;*/
  margin-bottom: 60px;
  max-width: 350px;
  width: 60%;
  /* margin-left: 20%;*/
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }
  @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
    .logo {
      width: 50%; } }
  @media (max-height: 768px) and (max-width: 1024px) and (min-aspect-ratio: 1 / 1) {
    .logo {
      width: 65%; } }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .logo {
      width: 70%; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    .logo {
      width: 80%; } }

@media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
  .start .language-select {
    margin-left: 0; } }

@media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
  .first + aside .vertical-center-wrapper {
    margin-top: 0; } }

@media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
  .first + aside .vertical-center-wrapper {
    margin-top: 0; } }

.vertical-center-wrapper {
  /*for within the banderole*/
  /* maybe only for 1280*800 */
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  margin-top: 0;
  left: 0;
  /*good solution, but here: refers to 1st page!*/
  /*    position: absolute;
            top: 50%;
            transform: translateY(-50%);*/ }
  .vertical-center-wrapper.first {
    margin-top: -5em; }
  .vertical-center-wrapper.small {
    margin-top: 0; }

.mob {
  display: none; }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .mob {
      display: block; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    .mob {
      display: block; } }

.std {
  display: block; }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .std {
      display: none; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    .std {
      display: none; } }

.banderole-aside {
  /* Positioning */
  position: relative;
  width: 31%;
  height: 100%;
  /*--FLO: 100vh leads to prob with centering on vertical-center-wrapper on iOS*/
  height: 100vh;
  /*box-sizing:border-box; leads to prob with negative margin */
  vertical-align: top;
  /* Display & Box Model */
  display: inline-block;
  border: none;
  /* Color & BG */
  background-color: #fff;
  color: #474a50;
  /* Text */
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.3rem;
  letter-spacing: 0.09em;
  white-space: normal; }
  @media (max-width: 1280px) {
    .banderole-aside {
      width: 35%; } }
  @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
    .banderole-aside {
      width: 40%; } }
  @media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
    .banderole-aside {
      width: 65%; } }
  @media (max-height: 768px) and (max-width: 1024px) and (min-aspect-ratio: 1 / 1) {
    #start .banderole-aside {
      width: 42%; }
    .banderole-aside:first-of-type {
      width: 40%; } }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .banderole-aside {
      width: 100%; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    .banderole-aside {
      width: 100%; } }
  .banderole-aside .arrow-book {
    font-size: 58%;
    margin-left: 0.78em;
    stroke-width: 2.5px; }
    .banderole-aside .arrow-book svg {
      stroke-width: 2.5px; }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      .banderole-aside .arrow-book {
        width: 40%;
        min-width: 0; } }
  .banderole-aside section {
    margin-top: 5em;
    padding: 0 19%;
    text-align: left; }
    @media (max-width: 1280px) {
      .banderole-aside section {
        padding: 14% 16%; } }
    @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
      .banderole-aside section {
        padding: 14% 14%;
        /*padding:$outerPadding/2 $outerPadding*2 - 5px $outerPadding $outerPadding*2 - 5px;*/ } }
    @media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
      .banderole-aside section {
        padding: 14% 14%; } }
    @media (max-height: 768px) and (max-width: 1024px) and (min-aspect-ratio: 1 / 1) {
      .banderole-aside section {
        padding: 14% 14%; } }
    @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
      .banderole-aside section {
        padding-top: 14%;
        padding: 10%;
        width: 100%;
        box-sizing: border-box;
        margin-right: 0; } }
  .banderole-aside p {
    padding: 5% 19%;
    text-align: justify; }
    @media (max-width: 1280px) {
      .banderole-aside p {
        padding: 2% 16%; } }
    @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
      .banderole-aside p {
        padding: 15px 55px 30px 55px; } }
    @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
      .banderole-aside p {
        padding: 7.5px 40px 15px 40px; } }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      .banderole-aside p {
        padding: 0px 55px 30px 55px; } }
  .banderole-aside h1, .banderole-aside h1.small, .banderole-aside h2 {
    font-family: "AdobeGaramondW01-Regula";
    line-height: 1em;
    height: 1em; }
  .banderole-aside h1 {
    font-size: 4rem;
    margin-bottom: 5%;
    text-align: center; }
    @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
      .banderole-aside h1 {
        /*font-size:3.1rem;*/
        /*margin-bottom:$outerPadding/2+10px;*/
        margin-bottom: 15.5px; } }
    @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
      .banderole-aside h1 {
        font-size: 3.1rem;
        margin-bottom: 5px; } }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      .banderole-aside h1 {
        font-size: 3.1rem;
        margin-bottom: 10px; } }
  .banderole-aside h1.small, .banderole-aside h2 {
    font-size: .75rem;
    text-transform: uppercase;
    text-align: left;
    line-height: 1.3em;
    height: auto; }
  .banderole-aside h1.small, .banderole-aside .aside-klapp {
    letter-spacing: .15em; }
  .banderole-aside h2.bottom {
    margin: 0 0 .25em;
    padding-top: 2em;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    font-weight: bold;
    text-transform: none; }
  .banderole-aside a:link,
  .banderole-aside a:visited,
  .banderole-aside a:active {
    color: #474a50;
    font-weight: normal;
    text-transform: none; }
  .banderole-aside a:hover {
    color: #b7ada5; }
  .banderole-aside #preise {
    margin-top: -1.6em; }
  .banderole-aside.aside-vt section, .banderole-aside.aside-klapp section {
    margin-top: 0;
    padding: 0 19% 19%; }
    .banderole-aside.aside-vt section:first-child, .banderole-aside.aside-klapp section:first-child {
      padding: 20% 19% 12%; }
    @media (max-width: 1280px) {
      .banderole-aside.aside-vt section, .banderole-aside.aside-klapp section {
        padding: 0 16% 14%; }
        .banderole-aside.aside-vt section:first-child, .banderole-aside.aside-klapp section:first-child {
          padding: 20% 16% 12%; } }
    @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
      .banderole-aside.aside-vt section, .banderole-aside.aside-klapp section {
        padding: 0 14% 14%;
        /*padding:$outerPadding/2 $outerPadding*2 - 5px $outerPadding $outerPadding*2 - 5px;*/ }
        .banderole-aside.aside-vt section:first-child, .banderole-aside.aside-klapp section:first-child {
          padding: 14% 14%; } }
    @media (max-height: 768px) and (max-width: 1024px) and (min-aspect-ratio: 1 / 1) {
      .banderole-aside.aside-vt section, .banderole-aside.aside-klapp section {
        padding: 0 14% 14%; }
        .banderole-aside.aside-vt section:first-child, .banderole-aside.aside-klapp section:first-child {
          padding: 21% 14% 13%; } }
    @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
      .banderole-aside.aside-vt section, .banderole-aside.aside-klapp section {
        /*old*/
        padding: 15%; } }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      .banderole-aside.aside-vt section, .banderole-aside.aside-klapp section {
        margin-top: 0px;
        padding: 0px 55px 30px 55px; } }
    @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
      .banderole-aside.aside-vt section, .banderole-aside.aside-klapp section {
        padding: 10%;
        width: 100%;
        box-sizing: border-box;
        margin-right: 0; }
        .banderole-aside.aside-vt section:first-child, .banderole-aside.aside-klapp section:first-child {
          padding-top: 14%; } }
  .banderole-aside.aside-klapp h1.small, .banderole-aside.aside-vt h1.small {
    margin-bottom: 2.2em; }
  .banderole-aside.aside-klapp p, .banderole-aside.aside-vt p {
    padding: 0; }
  .banderole-aside.aside-vt section:first-child {
    padding: 20% 19%; }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .banderole-aside.aside-vt {
      max-height: 100%;
      width: auto;
      box-sizing: border-box; }
      .banderole-aside.aside-vt .vertical-wrapper p {
        /*                column-count: 2;
                                    -webkit-column-gap: 3.8em;
                                    -moz-column-gap: 3.8em;
                                    column-gap: 3.8em;
                                    width: 41.5em;*/ } }
  .banderole-aside.aside-klapp header {
    width: 100%;
    margin-bottom: 2rem; }
  .banderole-aside.aside-klapp header > h1 {
    float: left; }
  .banderole-aside.aside-klapp h1, .banderole-aside.aside-klapp h1.small {
    font-family: "AdobeGaramondW01-Regula";
    margin: 0;
    margin-bottom: 1em;
    padding-right: 10px;
    text-transform: uppercase; }
  .banderole-aside.aside-klapp p.und {
    font-family: "AdobeGaramondW01-Regula";
    margin: 2em 0; }
  .banderole-aside.aside-klapp .bottom-line {
    border-bottom: 1px solid rgba(71, 74, 80, 0.8);
    height: 0.8em;
    overflow: hidden; }
  .banderole-aside.aside-klapp li {
    /*padding: .5em 0 1em 0;*/
    width: 100%; }
    @media (min-width: 1500px) {
      .banderole-aside.aside-klapp li {
        padding: 0.25em 0 0.5em 0; }
        .banderole-aside.aside-klapp li.sidemenu-li {
          padding: 0; } }
  .banderole-aside.aside-klapp li article {
    padding: 0 0 .5em 0; }
    @media (min-width: 1500px) {
      .banderole-aside.aside-klapp li article {
        padding: 0.25em 0 0.75em 0; } }
    #presse .banderole-aside.aside-klapp li article.first {
      padding-top: 0.9em; }
    .banderole-aside.aside-klapp li article h2 {
      padding-top: 0; }
    .banderole-aside.aside-klapp li article p {
      text-align: justify;
      padding: 0; }
  .banderole-aside.aside-klapp .item-header:hover, .banderole-aside.aside-klapp .item-header:active {
    color: #b7ada5;
    stroke: #b7ada5;
    cursor: pointer; }
  .banderole-aside.aside-klapp .item-description {
    clear: both; }
  .banderole-aside.aside-klapp .item-description {
    max-height: 0;
    overflow: hidden;
    transition: all 1s ease 0s; }
  .banderole-aside.aside-klapp .item.active .item-description {
    max-height: 100%;
    /*transition: max-height 1s ease;*/
    transition: all 1s ease 0.75s; }
    .banderole-aside.aside-klapp .item.active .item-description:last-of-type {
      margin-bottom: 1em; }
      @media (min-width: 1500px) {
        .banderole-aside.aside-klapp .item.active .item-description:last-of-type {
          margin-bottom: 0.5em; } }
  .banderole-aside.aside-klapp ul .item {
    max-height: 100vh;
    opacity: 1;
    overflow: hidden;
    transition-delay: 0.75s;
    transition-duration: 1s;
    transition-property: max-height, opacity; }
  .banderole-aside.aside-klapp .item .item-icon {
    transition: transform 1s ease;
    transform-origin: 50% 50%; }
  .banderole-aside.aside-klapp .item.active .item-icon {
    transition: transform 1s ease;
    transform: rotate(-180deg);
    transform-origin: 50% 50%; }
  .banderole-aside.aside-klapp .price_table {
    clear: both; }
  .banderole-aside.aside-klapp .item-header {
    cursor: pointer; }
    .banderole-aside.aside-klapp .item-header h2 {
      float: left; }
    .banderole-aside.aside-klapp .item-header .item-icon {
      margin-top: -0.6em;
      float: right; }
      .banderole-aside.aside-klapp .item-header .item-icon .svg-wrapper {
        height: 2.4em;
        width: 2.4em; }
        .banderole-aside.aside-klapp .item-header .item-icon .svg-wrapper svg {
          font-family: "AdobeGaramondW01-Regula";
          stroke: #474a50; }
  @media (max-height: 900px) {
    .banderole-aside.id-golfmuseum .par1 {
      display: none; } }

#presse h2.aside-klapp, #presse-mob h2.aside-klapp {
  line-height: 2em; }
  #presse h2.aside-klapp:first-line, #presse-mob h2.aside-klapp:first-line {
    line-height: 1.3em; }

@media (min-width: 1500px) {
  #presse .banderole-aside.aside-klapp ul .item {
    padding: 0.25em 0 1em 0; } }

/* aside settings - MAYBE: break apart for each pug-module - TODO: cleanup */
.banderole {
  overflow: auto;
  /*Doesnt work on Iphone*/
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10000px;
  white-space: nowrap;
  user-select: none;
  font-size: 0;
  /*      Til now unnecess.
        max-height:100%;
        max-height:100vh;*/ }

.banderole-img-wrapper {
  display: inline;
  letter-spacing: -1px;
  font-size: 0; }

.banderole-img {
  height: 100%;
  height: 100vh;
  min-width: 100%;
  box-sizing: border-box;
  border: none;
  display: inline-block;
  background: no-repeat center center/cover; }
  .banderole-img:not(.first) {
    border-right: solid 1px white; }
  @media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
    .banderole-img {
      min-width: 200%; } }
  .banderole-img.with-aside {
    min-width: 69%; }
    @media (max-width: 1280px) {
      .banderole-img.with-aside {
        min-width: 100%; }
        #start .banderole-img.with-aside, .banderole-img.with-aside.first {
          min-width: 65%; } }
    @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
      #start .banderole-img.with-aside, .banderole-img.with-aside.first {
        min-width: 60%; }
      .banderole-img.with-aside.banderole4-img1 {
        background-position: 100% 86%; } }
    @media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
      .banderole-img.with-aside:not(.first) {
        min-width: 200%; }
      .banderole-img.with-aside#service-packages {
        min-width: 100%; }
      .banderole-img.with-aside.banderole4-img1 {
        background-position: 100% 86%; }
      #start .banderole-img.with-aside, .banderole-img.with-aside.first {
        min-width: 100%; } }
    @media (max-height: 768px) and (max-width: 1024px) and (min-aspect-ratio: 1 / 1) {
      #start .banderole-img.with-aside, .banderole-img.with-aside.first {
        min-width: 60%; }
      .banderole-img.with-aside.banderole4-img1 {
        background-position: 100% 86%; } }
    @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
      .banderole-img.with-aside {
        min-width: 100%; }
        #start .banderole-img.with-aside, .banderole-img.with-aside.first {
          min-width: 100%; } }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      .banderole-img.with-aside {
        min-width: 100%; }
        #start .banderole-img.with-aside, .banderole-img.with-aside.first {
          min-width: 100%; } }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .banderole-img {
      min-width: 200%; }
      .banderole-img.with-aside {
        min-width: 200%; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    .banderole-img {
      min-width: 100%; }
      #start .banderole-img.with-aside {
        min-width: 60%; }
        #start .banderole-img.with-aside.first {
          width: 100%; } }
  .banderole-img.size2 {
    min-width: 61%; }
  @media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
    .banderole-img.first {
      min-width: 100vw; } }
  .banderole-img::before {
    /*        opacity: 0;*/
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: no-repeat center center/cover;
    /*transition: opacity 1s linear;*/ }
  .banderole-img.img-honey::before {
    /*opacity: 1;*/
    background-image: url("../Bilder/honeymoon_web.jpg");
    background-position: 50% 80%; }
  .banderole-img.img-golf::before {
    /*opacity: 1;*/
    background-image: url("../Bilder/thiergarten.jpg");
    background-position: 50% 59%; }

/* Test for iPhone - different way of attaching Pics */
.nojs .banderole1-img1, .cssPics.loaded .banderole1-img1 {
  background-image: url("../Bilder/banderole1_1.jpg"); }

.nojs .banderole1-img2, .cssPics.loaded .banderole1-img2 {
  background-image: url("../Bilder/banderole1_2.jpg"); }

.nojs .banderole1-img3, .cssPics.loaded .banderole1-img3 {
  background-image: url("../Bilder/banderole1_3.jpg"); }

.nojs .banderole1-img4, .cssPics.loaded .banderole1-img4 {
  background-image: url("../Bilder/banderole1_4.jpg"); }

.nojs .banderole1-img5, .cssPics.loaded .banderole1-img5 {
  background-image: url("../Bilder/banderole1_5.jpg"); }

.nojs .banderole1-img6, .cssPics.loaded .banderole1-img6 {
  background-image: url("../Bilder/banderole1_6.jpg"); }

.nojs .banderole1-img7, .cssPics.loaded .banderole1-img7 {
  background-image: url("../Bilder/banderole1_7.jpg"); }

.nojs .banderole1-img8, .cssPics.loaded .banderole1-img8 {
  background-image: url("../Bilder/banderole1_8.jpg"); }

.nojs .banderole1-img9, .cssPics.loaded .banderole1-img9 {
  background-image: url("../Bilder/banderole1_9.jpg"); }

.nojs .banderole2-img1, .cssPics.loaded .banderole2-img1 {
  background-image: url("../Bilder/banderole2_1.jpg"); }

.nojs .banderole2-img2, .cssPics.loaded .banderole2-img2 {
  background-image: url("../Bilder/banderole2_2.jpg"); }

.nojs .banderole2-img3, .cssPics.loaded .banderole2-img3 {
  background-image: url("../Bilder/banderole2_3.jpg"); }

.nojs .banderole2-img4, .cssPics.loaded .banderole2-img4 {
  background-image: url("../Bilder/banderole2_4.jpg"); }

.nojs .banderole2-img5, .cssPics.loaded .banderole2-img5 {
  background-image: url("../Bilder/banderole2_5.jpg"); }

.nojs .banderole2-img6, .cssPics.loaded .banderole2-img6 {
  background-image: url("../Bilder/banderole2_6.jpg"); }

.nojs .banderole2-img7, .cssPics.loaded .banderole2-img7 {
  background-image: url("../Bilder/banderole2_7.jpg"); }

.nojs .banderole2-img8, .cssPics.loaded .banderole2-img8 {
  background-image: url("../Bilder/banderole2_8.jpg"); }

.nojs .banderole2-img9, .cssPics.loaded .banderole2-img9 {
  background-image: url("../Bilder/banderole2_9.jpg"); }

.nojs .banderole3-img1, .cssPics.loaded .banderole3-img1 {
  background-image: url("../Bilder/banderole3_1.jpg"); }

.nojs .banderole3-img2, .cssPics.loaded .banderole3-img2 {
  background-image: url("../Bilder/banderole3_2.jpg"); }

.nojs .banderole3-img3, .cssPics.loaded .banderole3-img3 {
  background-image: url("../Bilder/banderole3_3.jpg"); }

.nojs .banderole3-img4, .cssPics.loaded .banderole3-img4 {
  background-image: url("../Bilder/banderole3_4.jpg"); }

.nojs .banderole3-img5, .cssPics.loaded .banderole3-img5 {
  background-image: url("../Bilder/banderole3_5.jpg"); }

.nojs .banderole3-img6, .cssPics.loaded .banderole3-img6 {
  background-image: url("../Bilder/banderole3_6.jpg"); }

.nojs .banderole3-img7, .cssPics.loaded .banderole3-img7 {
  background-image: url("../Bilder/banderole3_7.jpg"); }

.nojs .banderole3-img8, .cssPics.loaded .banderole3-img8 {
  background-image: url("../Bilder/banderole3_8.jpg"); }

.nojs .banderole3-img9, .cssPics.loaded .banderole3-img9 {
  background-image: url("../Bilder/banderole3_9.jpg"); }

.nojs .banderole4-img1, .cssPics.loaded .banderole4-img1 {
  background-image: url("../Bilder/banderole4_1.jpg"); }

.nojs .banderole4-img2, .cssPics.loaded .banderole4-img2 {
  background-image: url("../Bilder/banderole4_2.jpg"); }

.nojs .banderole4-img3, .cssPics.loaded .banderole4-img3 {
  background-image: url("../Bilder/banderole4_3.jpg"); }

.nojs .banderole4-img4, .cssPics.loaded .banderole4-img4 {
  background-image: url("../Bilder/banderole4_4.jpg"); }

.nojs .banderole4-img5, .cssPics.loaded .banderole4-img5 {
  background-image: url("../Bilder/banderole4_5.jpg"); }

.nojs .banderole4-img6, .cssPics.loaded .banderole4-img6 {
  background-image: url("../Bilder/banderole4_6.jpg"); }

.nojs .banderole4-img7, .cssPics.loaded .banderole4-img7 {
  background-image: url("../Bilder/banderole4_7.jpg"); }

.nojs .banderole4-img8, .cssPics.loaded .banderole4-img8 {
  background-image: url("../Bilder/banderole4_8.jpg"); }

.nojs .banderole4-img9, .cssPics.loaded .banderole4-img9 {
  background-image: url("../Bilder/banderole4_9.jpg"); }

.nojs .banderole5-img1, .cssPics.loaded .banderole5-img1 {
  background-image: url("../Bilder/banderole5_1.jpg"); }

.nojs .banderole5-img2, .cssPics.loaded .banderole5-img2 {
  background-image: url("../Bilder/banderole5_2.jpg"); }

.nojs .banderole5-img3, .cssPics.loaded .banderole5-img3 {
  background-image: url("../Bilder/banderole5_3.jpg"); }

.nojs .banderole5-img4, .cssPics.loaded .banderole5-img4 {
  background-image: url("../Bilder/banderole5_4.jpg"); }

.nojs .banderole5-img5, .cssPics.loaded .banderole5-img5 {
  background-image: url("../Bilder/banderole5_5.jpg"); }

.nojs .banderole5-img6, .cssPics.loaded .banderole5-img6 {
  background-image: url("../Bilder/banderole5_6.jpg"); }

.nojs .banderole5-img7, .cssPics.loaded .banderole5-img7 {
  background-image: url("../Bilder/banderole5_7.jpg"); }

.nojs .banderole5-img8, .cssPics.loaded .banderole5-img8 {
  background-image: url("../Bilder/banderole5_8.jpg"); }

.nojs .banderole5-img9, .cssPics.loaded .banderole5-img9 {
  background-image: url("../Bilder/banderole5_9.jpg"); }

.nojs .banderole6-img1, .cssPics.loaded .banderole6-img1 {
  background-image: url("../Bilder/banderole6_1.jpg"); }

.nojs .banderole6-img2, .cssPics.loaded .banderole6-img2 {
  background-image: url("../Bilder/banderole6_2.jpg"); }

.nojs .banderole6-img3, .cssPics.loaded .banderole6-img3 {
  background-image: url("../Bilder/banderole6_3.jpg"); }

.nojs .banderole6-img4, .cssPics.loaded .banderole6-img4 {
  background-image: url("../Bilder/banderole6_4.jpg"); }

.nojs .banderole6-img5, .cssPics.loaded .banderole6-img5 {
  background-image: url("../Bilder/banderole6_5.jpg"); }

.nojs .banderole6-img6, .cssPics.loaded .banderole6-img6 {
  background-image: url("../Bilder/banderole6_6.jpg"); }

.nojs .banderole6-img7, .cssPics.loaded .banderole6-img7 {
  background-image: url("../Bilder/banderole6_7.jpg"); }

.nojs .banderole6-img8, .cssPics.loaded .banderole6-img8 {
  background-image: url("../Bilder/banderole6_8.jpg"); }

.nojs .banderole6-img9, .cssPics.loaded .banderole6-img9 {
  background-image: url("../Bilder/banderole6_9.jpg"); }

.banderole1-img1.oneCssLoaded {
  background-image: url("../Bilder/banderole1_1.jpg"); }

.banderole1-img2.oneCssLoaded {
  background-image: url("../Bilder/banderole1_2.jpg"); }

.banderole1-img3.oneCssLoaded {
  background-image: url("../Bilder/banderole1_3.jpg"); }

.banderole1-img4.oneCssLoaded {
  background-image: url("../Bilder/banderole1_4.jpg"); }

.banderole1-img5.oneCssLoaded {
  background-image: url("../Bilder/banderole1_5.jpg"); }

.banderole1-img6.oneCssLoaded {
  background-image: url("../Bilder/banderole1_6.jpg"); }

.banderole1-img7.oneCssLoaded {
  background-image: url("../Bilder/banderole1_7.jpg"); }

.banderole1-img8.oneCssLoaded {
  background-image: url("../Bilder/banderole1_8.jpg"); }

.banderole1-img9.oneCssLoaded {
  background-image: url("../Bilder/banderole1_9.jpg"); }

.banderole2-img1.oneCssLoaded {
  background-image: url("../Bilder/banderole2_1.jpg"); }

.banderole2-img2.oneCssLoaded {
  background-image: url("../Bilder/banderole2_2.jpg"); }

.banderole2-img3.oneCssLoaded {
  background-image: url("../Bilder/banderole2_3.jpg"); }

.banderole2-img4.oneCssLoaded {
  background-image: url("../Bilder/banderole2_4.jpg"); }

.banderole2-img5.oneCssLoaded {
  background-image: url("../Bilder/banderole2_5.jpg"); }

.banderole2-img6.oneCssLoaded {
  background-image: url("../Bilder/banderole2_6.jpg"); }

.banderole2-img7.oneCssLoaded {
  background-image: url("../Bilder/banderole2_7.jpg"); }

.banderole2-img8.oneCssLoaded {
  background-image: url("../Bilder/banderole2_8.jpg"); }

.banderole2-img9.oneCssLoaded {
  background-image: url("../Bilder/banderole2_9.jpg"); }

.banderole3-img1.oneCssLoaded {
  background-image: url("../Bilder/banderole3_1.jpg"); }

.banderole3-img2.oneCssLoaded {
  background-image: url("../Bilder/banderole3_2.jpg"); }

.banderole3-img3.oneCssLoaded {
  background-image: url("../Bilder/banderole3_3.jpg"); }

.banderole3-img4.oneCssLoaded {
  background-image: url("../Bilder/banderole3_4.jpg"); }

.banderole3-img5.oneCssLoaded {
  background-image: url("../Bilder/banderole3_5.jpg"); }

.banderole3-img6.oneCssLoaded {
  background-image: url("../Bilder/banderole3_6.jpg"); }

.banderole3-img7.oneCssLoaded {
  background-image: url("../Bilder/banderole3_7.jpg"); }

.banderole3-img8.oneCssLoaded {
  background-image: url("../Bilder/banderole3_8.jpg"); }

.banderole3-img9.oneCssLoaded {
  background-image: url("../Bilder/banderole3_9.jpg"); }

.banderole4-img1.oneCssLoaded {
  background-image: url("../Bilder/banderole4_1.jpg"); }

.banderole4-img2.oneCssLoaded {
  background-image: url("../Bilder/banderole4_2.jpg"); }

.banderole4-img3.oneCssLoaded {
  background-image: url("../Bilder/banderole4_3.jpg"); }

.banderole4-img4.oneCssLoaded {
  background-image: url("../Bilder/banderole4_4.jpg"); }

.banderole4-img5.oneCssLoaded {
  background-image: url("../Bilder/banderole4_5.jpg"); }

.banderole4-img6.oneCssLoaded {
  background-image: url("../Bilder/banderole4_6.jpg"); }

.banderole4-img7.oneCssLoaded {
  background-image: url("../Bilder/banderole4_7.jpg"); }

.banderole4-img8.oneCssLoaded {
  background-image: url("../Bilder/banderole4_8.jpg"); }

.banderole4-img9.oneCssLoaded {
  background-image: url("../Bilder/banderole4_9.jpg"); }

.banderole5-img1.oneCssLoaded {
  background-image: url("../Bilder/banderole5_1.jpg"); }

.banderole5-img2.oneCssLoaded {
  background-image: url("../Bilder/banderole5_2.jpg"); }

.banderole5-img3.oneCssLoaded {
  background-image: url("../Bilder/banderole5_3.jpg"); }

.banderole5-img4.oneCssLoaded {
  background-image: url("../Bilder/banderole5_4.jpg"); }

.banderole5-img5.oneCssLoaded {
  background-image: url("../Bilder/banderole5_5.jpg"); }

.banderole5-img6.oneCssLoaded {
  background-image: url("../Bilder/banderole5_6.jpg"); }

.banderole5-img7.oneCssLoaded {
  background-image: url("../Bilder/banderole5_7.jpg"); }

.banderole5-img8.oneCssLoaded {
  background-image: url("../Bilder/banderole5_8.jpg"); }

.banderole5-img9.oneCssLoaded {
  background-image: url("../Bilder/banderole5_9.jpg"); }

.banderole6-img1.oneCssLoaded {
  background-image: url("../Bilder/banderole6_1.jpg"); }

.banderole6-img2.oneCssLoaded {
  background-image: url("../Bilder/banderole6_2.jpg"); }

.banderole6-img3.oneCssLoaded {
  background-image: url("../Bilder/banderole6_3.jpg"); }

.banderole6-img4.oneCssLoaded {
  background-image: url("../Bilder/banderole6_4.jpg"); }

.banderole6-img5.oneCssLoaded {
  background-image: url("../Bilder/banderole6_5.jpg"); }

.banderole6-img6.oneCssLoaded {
  background-image: url("../Bilder/banderole6_6.jpg"); }

.banderole6-img7.oneCssLoaded {
  background-image: url("../Bilder/banderole6_7.jpg"); }

.banderole6-img8.oneCssLoaded {
  background-image: url("../Bilder/banderole6_8.jpg"); }

.banderole6-img9.oneCssLoaded {
  background-image: url("../Bilder/banderole6_9.jpg"); }

@media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
  .banderole4-img1:before {
    background-position: 100% 50%; } }

@media (max-height: 768px) and (max-width: 1024px) and (min-aspect-ratio: 1 / 1) {
  .banderole1-img1 {
    background-position: 70% 50%; } }

@media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
  .banderole4-img1.with-aside, .banderole4-img1:before {
    min-width: 50%; } }

.banderole4-img1 {
  /*background-position: center 86%;*/ }

.banderole4-img3 {
  background-position: center 100%; }

.banderole5-img2, .id-packages ul, .id-packages header {
  -webkit-backface-visibility: hidden; }

.banderole5-img2 {
  background-position: 0% 60%; }

.banderole3-img7 {
  background-position-y: 100%; }

/* just banderole settings - TODO: move bgs to bgs. Create Map */
#kontakt-main, #kontakt-mob {
  height: 15em;
  height: auto;
  color: #fff; }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    #kontakt-main, #kontakt-mob {
      height: 50%; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    #kontakt-main, #kontakt-mob {
      height: 60%; } }

footer {
  font-size: 0.75rem;
  width: 100%;
  height: 100%;
  background-color: #474a50;
  box-sizing: border-box;
  text-align: center;
  padding: 3em;
  line-height: 1.8em; }
  footer p {
    hyphens: none;
    width: 100%;
    /*        position:absolute;
        top:50%;
        left:50%;
        transform: translate3d(-50%,-50%,0);*/ }
  footer span.gara {
    font-family: "AdobeGaramondW01-Regula"; }

/*Ende Buchung*/
#nl-form {
  display: none; }
  #nl-form.show {
    display: block; }

.formular.show {
  display: none; }

#nl-text {
  display: none; }

input,
#nl-text,
#saving {
  font-family: 'titillium', Titillium, sans-serif;
  width: 100%;
  max-width: 32em;
  box-sizing: border-box;
  padding: 0.8em;
  margin: 4px 0;
  text-transform: uppercase;
  font-size: 1em;
  letter-spacing: 0.15em;
  font-weight: 300; }
  @media (min-width: 677px) {
    input,
    #nl-text,
    #saving {
      width: 65%; } }

#nl-form .checkbox--wrapper {
  font-family: 'titillium', Titillium, sans-serif;
  width: 100%;
  max-width: 32em;
  margin: 4px auto;
  text-align: left;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex; }
  @media (min-width: 677px) {
    #nl-form .checkbox--wrapper {
      width: 65%; } }
  #nl-form .checkbox--wrapper > input, #nl-form .checkbox--wrapper > label {
    display: inline-block; }
  #nl-form .checkbox--wrapper > input {
    width: 25px; }
  #nl-form .checkbox--wrapper label {
    width: 90%; }
    #nl-form .checkbox--wrapper label a {
      text-transform: none; }

input {
  border-radius: 0;
  border: 0; }

.gogogo {
  width: 45%;
  margin: 24px 27.5%;
  background-color: #b7ada5 !important;
  color: white;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none; }
  @media (max-width: 450px) {
    .gogogo {
      margin: 24px 20%;
      margin-left: 0;
      /*evtl auch global*/
      width: 60%; } }

#saving, #nl-text {
  display: none;
  padding-left: 0;
  padding-right: 0; }
  #saving.show, #nl-text.show {
    display: inline-block; }

/* just footer settings - maybe move to main */
/*svg{stroke-width:0.00015em;}*/
li svg {
  stroke-width: 2.5px; }

svg.arrow-right, svg.arrow-left {
  margin-left: 0.8em; }

.banderole-next-fixed li svg {
  /*margin-left: 0.78em;*/ }

svg {
  height: 100%;
  width: 100%; }

.sidemenu-wrapper li .svg-wrapper, .aside-bottom li .svg-wrapper, .aside-top li .svg-wrapper, .banderole-next-fixed li .svg-wrapper {
  position: absolute;
  top: 50%;
  height: 2em;
  margin-top: -1em;
  width: 2em; }
  .sidemenu-wrapper li .svg-wrapper svg, .aside-bottom li .svg-wrapper svg, .aside-top li .svg-wrapper svg, .banderole-next-fixed li .svg-wrapper svg {
    transition: stroke 200ms linear;
    stroke: #474a50;
    fill: #474a50; }

i.fa:not(.fa-facebook):not(.fa-instagram):not(.fa-tripadvisor) {
  border-radius: 100%;
  /*background: linear-gradient(90deg, #FFC0CB 50%, rgba(0, 255, 255, 0) 50%),linear-gradient(180deg, rgba(255,0,0,0.8) 50%,transparent 50%);*/
  font-size: 0.8rem;
  height: 1.7em;
  width: 1.7em;
  overflow: hidden;
  border: 1px solid #474a50; }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    i.fa:not(.fa-facebook):not(.fa-instagram):not(.fa-tripadvisor) {
      font-size: 0.6875rem; } }
  i.fa:not(.fa-facebook):not(.fa-instagram):not(.fa-tripadvisor):before {
    /*                transition: color 0.25s linear 0;*/ }
  i.fa:not(.fa-facebook):not(.fa-instagram):not(.fa-tripadvisor).here:before, i.fa:not(.fa-facebook):not(.fa-instagram):not(.fa-tripadvisor).book:before {
    content: "\00b7";
    transform: translate3d(-41%, -55%, 0);
    margin-top: 0;
    margin-left: 0; }

i.fa-angle-right:before, i.fa-angle-up:before, i.fa-angle-left:before, i.fa-angle-down:before {
  content: "\203A"; }

i.book:after {
  content: "";
  position: absolute;
  height: 50%;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  border: solid 1px white;
  transition: border-color 250ms linear 0;
  border-radius: 100%; }
  .sidemenu-icon i.book:after {
    border-color: #474a50; }

i:not(.fa-facebook):not(.fa-instagram):not(.fa-tripadvisor):before {
  font-family: "AdobeGaramondW01-Regula";
  font-size: 2.4em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-35%, -55%, 0);
  line-height: 1; }

.circle1 {
  position: relative;
  height: 26px;
  width: 26px;
  font-size: 0.8rem;
  overflow: hidden;
  border: 1px solid #474a50;
  border-radius: 100%;
  box-sizing: border-box;
  display: block;
  line-height: 100%; }

.innerArrow {
  font-family: "AdobeGaramondW01-Regula";
  font-size: 30px;
  position: absolute;
  display: block;
  box-sizing: border-box;
  content: "\00b7";
  height: 100%;
  width: 100%;
  line-height: 50%;
  text-align: center;
  border: solid 1px transparent;
  vertical-align: middle; }

.sidemenu-icon {
  clear: both;
  float: left;
  width: 25%; }

.sidemenu-text {
  /*Analog zum Menü*/
  /*float:left;*/
  text-align: left;
  margin-left: 2.2rem;
  text-transform: uppercase;
  white-space: nowrap;
  transition: color 250ms linear; }
  #menu-con .sidemenu-text, #mobile-menu-con .sidemenu-text {
    margin-left: 0; }

.sidemenu-li {
  /*Analog zum Menü*/
  position: relative;
  box-sizing: border-box;
  font-size: 0.75rem;
  line-height: 2.6em;
  height: 2.6em;
  display: block;
  letter-spacing: 0.15em; }
  .sidemenu-li .sidemenu-text:hover, .sidemenu-li:hover .sidemenu-text {
    color: #b7ada5; }
  .sidemenu-li a {
    color: #474a50; }
    #menu-con .sidemenu-li a, #mobile-menu-con .sidemenu-li a {
      color: white; }
  .sidemenu-li:hover {
    color: #b7ada5; }
    .sidemenu-li:hover svg {
      stroke: #b7ada5; }
  .sidemenu-li i {
    position: absolute;
    left: 0;
    top: 50%;
    transition: border-color 500ms linear; }
    .sidemenu-li i.arrow-up {
      transform: translateY(-50%) rotate(-90deg); }
    .sidemenu-li i.arrow-right, .sidemenu-li i.book {
      transform: translateY(-50%); }
    .sidemenu-li i.arrow-down {
      transform: translateY(-50%) rotate(90deg); }
    .sidemenu-li i.book:after {
      border: solid 1px; }

.banderole-next, .banderole-next-fixed {
  /* Display & Box Model */
  padding: 30px;
  width: auto;
  /* Color & BG */
  color: #474a50;
  /* Text */
  text-transform: uppercase;
  white-space: nowrap;
  height: 30px; }
  .banderole-next a:link, .banderole-next a:link div, .banderole-next a:visited, .banderole-next a:visited div, .banderole-next a:active, .banderole-next a:active div, .banderole-next-fixed a:link, .banderole-next-fixed a:link div, .banderole-next-fixed a:visited, .banderole-next-fixed a:visited div, .banderole-next-fixed a:active, .banderole-next-fixed a:active div {
    color: #474a50; }
  .banderole-next i, .banderole-next-fixed i {
    /*        background-color: rgba(255,255,255,0.7);*/ }
  .banderole-next .sidemenu-icon, .banderole-next-fixed .sidemenu-icon {
    clear: both;
    float: left;
    width: 25%; }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .banderole-next, .banderole-next-fixed {
      padding: 15px; } }

.banderole .banderole-next-fixed {
  position: fixed;
  bottom: 0;
  left: 84.5%;
  z-index: 200;
  transform: translate3d(-50%, 0, 0); }
  @media (max-width: 1100px) {
    .banderole .banderole-next-fixed {
      left: 80%; } }
  @media (max-height: 768px) and (max-width: 1024px) and (min-aspect-ratio: 1 / 1) {
    .banderole .banderole-next-fixed {
      left: 84.5%; } }
  @media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
    .banderole .banderole-next-fixed {
      left: 67.5%; } }
  @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
    .banderole .banderole-next-fixed {
      /* greift schon bei 1280x800, da ist aside normal breit */
      /*left: 83%;*/ } }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .banderole .banderole-next-fixed {
      left: 50%;
      padding: 15px; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    .banderole .banderole-next-fixed {
      right: 55px;
      left: auto;
      padding: 15px;
      padding-right: 0;
      transform: none; }
      .banderole .banderole-next-fixed .arrow {
        left: auto;
        right: 0; }
      .banderole .banderole-next-fixed .sidemenu-text {
        margin-left: 0;
        margin-right: 2.2rem; } }

.banderole-next,
.banderole-next-fixed,
.banderole-nav-right,
.banderole-nav-left {
  cursor: pointer; }
  .banderole-next .svg-wrapper,
  .banderole-next-fixed .svg-wrapper,
  .banderole-nav-right .svg-wrapper,
  .banderole-nav-left .svg-wrapper {
    position: static; }
  .banderole-next:hover svg,
  .banderole-next-fixed:hover svg,
  .banderole-nav-right:hover svg,
  .banderole-nav-left:hover svg {
    stroke: #b7ada5; }

.banderole-nav-right, .banderole-nav-left {
  z-index: 200;
  position: fixed;
  display: none;
  padding: 30px;
  width: auto; }
  .banderole-nav-right .svg-wrapper, .banderole-nav-left .svg-wrapper {
    width: 2em;
    height: 100%;
    margin: 0;
    position: relative;
    top: 0; }
    .banderole-nav-right .svg-wrapper svg, .banderole-nav-left .svg-wrapper svg {
      stroke-width: 1.5px;
      stroke: white; }
  .banderole-nav-right .sidemenu-text, .banderole-nav-left .sidemenu-text {
    display: none; }

#navigation--scrollerLine {
  position: relative;
  height: 1px;
  width: 45px;
  overflow: hidden; }
  #navigation--scrollerLine span {
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: white; }

.banderole-nav-right .arrow, .banderole-next .arrow {
  animation: arrowLeft 3s infinite;
  animation-iteration-count: infinite;
  -webkit-animation: arrowLeft 3s infinite;
  /* Chrome, Safari, Opera */
  -webkit-animation-iteration-count: infinite;
  /* Chrome, Safari, Opera */
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

.banderole-nav-left .arrow {
  animation: arrowRight 3s infinite;
  animation-iteration-count: infinite;
  -webkit-animation: arrowRight 3s infinite;
  /* Chrome, Safari, Opera */
  -webkit-animation-iteration-count: infinite;
  /* Chrome, Safari, Opera */
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

@keyframes arrowLeft {
  0%, 10%, 50%, 90%, 100% {
    transform: translateX(0); }
  30% {
    transform: translateX(-10px); }
  70% {
    transform: translateX(-10px); } }

@keyframes arrowRight {
  0%, 10%, 50%, 90%, 100% {
    transform: translateX(0); }
  30% {
    transform: translateX(10px); }
  70% {
    transform: translateX(10px); } }

.banderole.scrolled .banderole-nav-right {
  left: auto;
  right: 0; }

.banderole.scrolled .banderole-nav-left {
  left: 0;
  right: auto; }

.banderole.scrolled .banderole-nav-right, .banderole.scrolled .banderole-nav-left {
  /*width: 25px;*/
  display: block;
  bottom: auto;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  height: 10%;
  height: 45px;
  /* references for the moment to burger-icon */ }
  .banderole.scrolled .banderole-nav-right li, .banderole.scrolled .banderole-nav-left li {
    display: inline;
    height: auto; }

/* all ui components arrows and stuff */
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 27.07.2016, 16:00:24
    Author     : Florian
*/
.mobExtra {
  display: none; }

body.mobileOnly, html.mobileOnly {
  position: static;
  overflow: visible;
  height: auto;
  max-height: none; }

body.mobileOnly {
  /*    max-width: none; */
  /*-webkit-overflow-scrolling: touch;*/ }
  body.mobileOnly section {
    transform: translate3d(0, 0, 0); }
  body.mobileOnly a {
    -webkit-user-select: none !important; }
  body.mobileOnly .ol_bg, body.mobileOnly .scroll {
    display: block; }
  body.mobileOnly #service-packages {
    position: relative; }
  body.mobileOnly section.ol_bg:not(#start-main) {
    display: none; }
  body.mobileOnly #presse-main, body.mobileOnly section#service-main {
    /* haben mobile Version */
    display: none; }
  body.mobileOnly div#mouseCon {
    height: auto;
    display: block; }
  body.mobileOnly .ol_bg {
    width: 100%;
    /*min-height: 100vh; warum???*/
    height: auto;
    background-position: center top;
    z-index: 9; }
    body.mobileOnly .ol_bg#kontakt-main {
      min-height: 0; }
    body.mobileOnly .ol_bg#start-main {
      top: auto; }
  body.mobileOnly .scroll {
    position: relative; }
  body.mobileOnly #start-main {
    z-index: 1; }
  body.mobileOnly .page2 {
    z-index: 3; }
    body.mobileOnly .page2 .banderole-next-fixed {
      z-index: 4; }
  body.mobileOnly .page3 {
    z-index: 5; }
    body.mobileOnly .page3 .banderole-next-fixed {
      z-index: 6; }
  body.mobileOnly .page4 {
    z-index: 7; }
    body.mobileOnly .page4 .banderole-next-fixed {
      z-index: 8; }
  body.mobileOnly .page5 {
    z-index: 9; }
    body.mobileOnly .page5 .banderole-next-fixed {
      z-index: 10; }
  body.mobileOnly .mobExtra {
    z-index: 11; }
  body.mobileOnly #loader {
    height: 100%;
    z-index: 201; }
    body.mobileOnly #loader #loader-bg, body.mobileOnly #loader aside {
      height: 100%;
      margin: 0;
      padding: 0; }
    body.mobileOnly #loader aside.loader {
      display: none; }
  body.mobileOnly .start .banderole-img-wrapper > div:not(.first), body.mobileOnly .start .banderole-img-wrapper > aside:not(.id-start) {
    display: none; }
  body.mobileOnly .bndClipper {
    position: relative;
    float: right;
    width: auto;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    clip: rect(auto, auto, auto, auto);
    /*
        unwanted result in Chrome. Check if needed on different browsers
        clip-path: inset(0px 0px 0px 0px);*/ }
  body.mobileOnly .banderole {
    position: relative;
    padding: 0;
    max-height: 100%;
    max-width: 100%;
    max-height: 100vh;
    max-width: 100vw;
    box-sizing: border-box;
    overflow: hidden; }
    body.mobileOnly .banderole.banderole-active {
      overflow-x: visible; }
  body.mobileOnly .noVP section:not(.mobExtra) {
    overflow-y: hidden; }
  body.mobileOnly .noVP .banderole {
    min-height: 100%;
    max-height: 100%;
    max-width: 100%; }
  body.mobileOnly .noVP .bndClipper {
    height: 100%; }
  body.mobileOnly .noVP .banderole-img-wrapper {
    min-height: 100%; }
  body.mobileOnly .noVP .banderole-img {
    min-height: 100%;
    height: 100%;
    max-height: 100%; }
  body.mobileOnly .noVP .banderole-aside {
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    box-sizing: border-box;
    /*padding:0px 5%  0px;*/ }
    body.mobileOnly .noVP .banderole-aside section {
      position: relative;
      height: 100%;
      margin: 0;
      padding: 10%;
      box-sizing: border-box; }
  body.mobileOnly .noVP .mobExtra .banderole {
    max-height: none;
    min-height: none; }
  body.mobileOnly .noVP .mobExtra .banderole-img {
    min-height: 0;
    height: 55%;
    max-height: 55%; }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      body.mobileOnly .noVP .mobExtra .banderole-img {
        height: 100%;
        max-height: 100%; } }
  body.mobileOnly .noVP .mobExtra .banderole-aside {
    max-height: none;
    min-height: 0;
    height: auto; }
    body.mobileOnly .noVP .mobExtra .banderole-aside section {
      height: auto; }
    body.mobileOnly .noVP .mobExtra .banderole-aside li {
      max-height: none; }
  body.mobileOnly .noVP .mobExtra .banderole-img-wrapper {
    min-height: 0; }
  body.mobileOnly .banderole-img-wrapper {
    max-height: 100%;
    max-width: 100%;
    overflow-y: hidden;
    box-sizing: border-box; }
  body.mobileOnly .banderole-img.first {
    position: relative;
    border: 0; }
  body.mobileOnly .banderole-img.first + aside {
    margin-left: 0; }
  body.mobileOnly :not(#start-main) .banderole:not(.banderole-active) .banderole-img:not(.first) {
    display: none; }
  body.mobileOnly footer {
    position: relative;
    text-align: left; }
  body.mobileOnly .burger.burger-vertical {
    position: fixed;
    top: 20px;
    left: 10px; }
    body.mobileOnly .burger.burger-vertical.loading div {
      background-color: white; }
    body.mobileOnly .burger.burger-vertical.active {
      left: 33px;
      top: 30px; }
  body.mobileOnly .banderole-next-fixed {
    top: 0;
    bottom: auto;
    padding: 20px; }
  body.mobileOnly .banderole.scrolled .banderole-nav-left {
    display: none; }
  body.mobileOnly .banderole.scrolled .banderole-nav-right, body.mobileOnly .banderole.scrolled .banderole-nav-left {
    top: 0;
    transform: none;
    padding: 19px; }
    body.mobileOnly .banderole.scrolled .banderole-nav-right .svg-wrapper, body.mobileOnly .banderole.scrolled .banderole-nav-left .svg-wrapper {
      width: 28px;
      height: 28px; }
    body.mobileOnly .banderole.scrolled .banderole-nav-right svg, body.mobileOnly .banderole.scrolled .banderole-nav-left svg {
      stroke: #474a50;
      stroke-width: 0.15em;
      height: 28px; }
  body.mobileOnly .aside-top:not(.language-select) {
    padding: 17px;
    top: auto;
    bottom: 0; }
    body.mobileOnly .aside-top:not(.language-select) li .svg-wrapper {
      height: 28px;
      width: 28px;
      margin-top: -14px;
      transform: rotateZ(180deg); }
  body.mobileOnly .aside-bottom {
    bottom: auto;
    top: 0;
    padding: 17px; }
    body.mobileOnly .aside-bottom li .svg-wrapper {
      height: 28px;
      width: 28px; }
  body.mobileOnly .language-select {
    top: 15px;
    bottom: auto;
    transform: translate3d(-50%, 0, 0);
    margin: 0;
    padding: 0; }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    body.mobileOnly .aside-top:not(.language-select) {
      padding: 15px;
      padding-right: 0;
      right: 55px;
      left: auto;
      transform: none; }
      body.mobileOnly .aside-top:not(.language-select) .sidemenu-text {
        margin-left: 0;
        margin-right: 2.2rem; }
      body.mobileOnly .aside-top:not(.language-select) .arrow {
        left: auto;
        right: 0; }
    body.mobileOnly .aside-bottom {
      padding: 15px;
      padding-right: 0;
      right: 55px;
      left: auto;
      transform: none; } }
  body.mobileOnly .logo-con {
    top: 55%;
    margin-top: -50%; }
    @media (max-height: 580px) and (max-aspect-ratio: 2 / 1) {
      body.mobileOnly .logo-con {
        top: 48%; } }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      body.mobileOnly .logo-con {
        display: none; } }
    @media (max-height: 480px) and (max-aspect-ratio: 1 / 1) {
      body.mobileOnly .logo-con {
        margin-top: -34%; } }
  body.mobileOnly .start .sidemenu-wrapper {
    top: 70%;
    padding: 0; }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      body.mobileOnly .start .sidemenu-wrapper {
        top: 50%;
        bottom: auto;
        padding: 0;
        height: 6.6em;
        margin-left: -1em; } }
    @media (max-height: 480px) and (max-aspect-ratio: 1 / 1) {
      body.mobileOnly .start .sidemenu-wrapper {
        top: 72%; } }
    body.mobileOnly .start .sidemenu-wrapper li .svg-wrapper.arrow-up {
      transform: rotate(180deg); }
    body.mobileOnly .start .sidemenu-wrapper ul {
      position: relative; }
    body.mobileOnly .start .sidemenu-wrapper.social-buttons {
      padding-left: 0;
      padding-right: 0;
      top: 80%; }
      @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
        body.mobileOnly .start .sidemenu-wrapper.social-buttons {
          top: 84%; } }
      @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
        body.mobileOnly .start .sidemenu-wrapper.social-buttons {
          top: auto;
          bottom: 20px;
          margin-left: 0.65em;
          height: auto;
          padding: 0;
          transform: translate3d(-50%, 0, 0); } }
      @media (max-height: 480px) and (max-aspect-ratio: 1 / 1) {
        body.mobileOnly .start .sidemenu-wrapper.social-buttons {
          margin-left: 0.65em;
          height: auto;
          padding: 0;
          transform: translate3d(-50%, 0, 0); } }
  body.mobileOnly .start .sidemenu-wrapper:not(.social-buttons) #l_gotoSuite {
    position: absolute;
    top: 100%; }
  body.mobileOnly .start .sidemenu-wrapper:not(.social-buttons) #l_gotoContact {
    position: absolute;
    top: 200%; }
  body.mobileOnly .banderole-next.simple {
    display: none; }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    body.mobileOnly #service-main.banderole-img {
      background-position-y: 71%; } }
  body.mobileOnly .mobExtra .banderole, body.mobileOnly #service-main .banderole {
    max-height: none; }
  body.mobileOnly .mobExtra .banderole-img-wrapper, body.mobileOnly #service-main .banderole-img-wrapper {
    display: block;
    max-height: none; }
  body.mobileOnly .mobExtra .banderole-img, body.mobileOnly .mobExtra aside, body.mobileOnly #service-main .banderole-img, body.mobileOnly #service-main aside {
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    max-height: none;
    border: 0; }
  body.mobileOnly .mobExtra .banderole-img, body.mobileOnly #service-main .banderole-img {
    height: 50vh; }
  body.mobileOnly .mobExtra aside, body.mobileOnly #service-main aside {
    height: auto; }
  body.mobileOnly .mobExtra aside section, body.mobileOnly #service-main aside section {
    width: 100%;
    padding: 13%;
    box-sizing: border-box; }
    body.mobileOnly .mobExtra aside section#preise, body.mobileOnly #service-main aside section#preise {
      padding-top: 1%; }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      body.mobileOnly .mobExtra aside section, body.mobileOnly #service-main aside section {
        padding: 7%; } }
  body.mobileOnly .mobExtra .banderole-next, body.mobileOnly .mobExtra .banderole-next-fixed, body.mobileOnly #service-main .banderole-next, body.mobileOnly #service-main .banderole-next-fixed {
    display: none; }
  body.mobileOnly .mobExtra .scroll-up, body.mobileOnly #service-main .scroll-up {
    display: none; }
  body.mobileOnly #kontakt-main {
    position: relative;
    z-index: 10;
    height: auto; }
    body.mobileOnly #kontakt-main footer {
      height: auto;
      padding: 10%; }
    body.mobileOnly #kontakt-main p {
      height: auto;
      position: relative;
      left: auto;
      top: auto;
      transform: none;
      box-sizing: border-box;
      max-width: 100vw;
      padding: 0;
      padding-bottom: 0;
      text-align: left; }
    body.mobileOnly #kontakt-main span {
      display: block; }
    body.mobileOnly #kontakt-main span.trenner {
      display: none; }
  body.mobileOnly .banderole-img {
    -webkit-user-select: none;
    will-change: transform; }
    @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
      body.mobileOnly .banderole-img:not(.first) {
        min-width: 200%; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    body.mobileOnly .banderole-img.with-aside.banderole4-img1 {
      background-position: 50% 57%; } }
  body.mobileOnly .mobExtra .banderole-img.with-aside {
    min-width: 100%; }
  body.mobileOnly .banderole5-img1, body.mobileOnly .banderole5-img2 {
    min-width: 100%; }
  body.mobileOnly .banderole4-img2 {
    background-position: 50% 35%; }
  body.mobileOnly .banderole4-img3 {
    background-position: 50% 86%; }
  body.mobileOnly .banderole4-img4 {
    background-position: 50% 57%; }
  body.mobileOnly .banderole4-img5 {
    background-image: url("../Bilder/banderole6_1.jpg") !important;
    background-position: 50% 57%; }
  body.mobileOnly .banderole5-img1.first:before {
    background-size: 270%;
    background-position: 44% 56%; }
  body.mobileOnly .banderole5-img2 {
    background-position: 54% 74%; }
  body.mobileOnly #menu-con {
    display: none; }
  body.mobileOnly #mobile-menu-con {
    display: block; }
  body.mobileOnly li#l_buchen {
    padding-top: 2em; }
  body.mobileOnly .banderole-aside {
    padding: 0; }
    body.mobileOnly .banderole-aside:first-of-type {
      width: 100%; }
    body.mobileOnly .banderole-aside #suite1 h1, body.mobileOnly .banderole-aside #suite2 h1, body.mobileOnly .banderole-aside #suite3 h1 {
      font-size: 4rem;
      margin-bottom: 5%; }
      @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
        body.mobileOnly .banderole-aside #suite1 h1, body.mobileOnly .banderole-aside #suite2 h1, body.mobileOnly .banderole-aside #suite3 h1 {
          font-size: 3.1rem;
          margin-bottom: 5px; } }
    body.mobileOnly .banderole-aside.aside-klapp .item-header {
      pointer-events: none; }
      body.mobileOnly .banderole-aside.aside-klapp .item-header h2 {
        padding-bottom: 0.3em; }
    body.mobileOnly .banderole-aside.aside-klapp li.item:not(:first-of-type) {
      margin-top: 2em; }
  body.mobileOnly .aside-cta .item-icon {
    line-height: 2.2em; }
  body.mobileOnly .vertical-center-wrapper {
    transform: translate3d(0, -60%, 0); }
    @media (max-height: 580px) and (max-aspect-ratio: 2 / 1) {
      body.mobileOnly .vertical-center-wrapper {
        transform: translate3d(0, -50%, 0); } }
  body.mobileOnly .mobExtra .item .item-description {
    max-height: none; }
    body.mobileOnly .mobExtra .item .item-description h2 {
      padding-top: 0.5em; }
  body.mobileOnly .mobExtra .item .item-header svg {
    display: none; }
  body.mobileOnly td:last-of-type {
    text-align: right;
    width: auto;
    white-space: nowrap; }
    body.mobileOnly td:last-of-type .mob_br {
      display: inline; }

/* new mobile */
body.nojs .banderole-aside.aside-klapp .item-description {
  max-height: none; }

body.nojs .banderole-aside.aside-klapp li article.first {
  padding-top: 0.3em; }

@media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
  body.nojs .banderole-img.first + .parent-start {
    margin-left: 25%; } }

body.nojs .banderole-aside.aside-klapp .item-header span {
  display: none; }

body.nojs .nav-page .sidemenu-text {
  margin-left: 0; }

body.nojs .nav-page i {
  display: none; }

@keyframes fadeOut {
  0% {
    opacity: 1;
    max-height: 100%;
    min-height: 100%; }
  50% {
    opacity: 0;
    display: none;
    max-height: 100%;
    min-height: 100%; }
  100% {
    opacity: 0;
    max-height: 0;
    min-height: 0; } }

body.nojs #loader {
  display: block;
  animation: fadeOut 6s linear 2s 1 forwards;
  /* IE 10+, Fx 29+ */ }
  body.nojs #loader div.loader {
    display: block;
    animation: fadeOut 6s linear 2s 1 forwards;
    /* IE 10+, Fx 29+ */ }
  body.nojs #loader aside {
    display: none; }

body.nojs .banderole-img.first.banderole4-img1 {
  position: absolute;
  top: 0;
  left: 0;
  animation: fadeOut 6s linear 5s 1 forwards;
  /* IE 10+, Fx 29+ */
  z-index: 10; }

body.nojs .close {
  display: none;
  position: fixed;
  top: 30px;
  left: 30px;
  width: 40px;
  height: 40px;
  z-index: 3000;
  transform: translate3d(0, 0, 3000px); }

body.nojs .burger {
  transform: translate3d(0, 0, 2000px); }
  body.nojs .burger:hover {
    left: 33px;
    top: 30px;
    height: 56.4px; }
    @media (max-aspect-ratio: 1 / 1) and (max-width: 650px) {
      body.nojs .burger:hover {
        height: 37.6px; } }
    body.nojs .burger:hover .n1, body.nojs .burger:hover .n2 {
      background-color: white;
      margin-left: 50%; }
    body.nojs .burger:hover .n1 {
      transform: translateX(50%) rotate(45deg); }
    body.nojs .burger:hover .n2 {
      transform: rotate(-45deg); }
    body.nojs .burger:hover + div#menu-con, body.nojs .burger:hover ~ div#mobile-menu-con {
      transform: translate3d(0, 0, 20px); }
    body.nojs .burger:hover ~ .close {
      display: block; }

/* simple no js version */
/*@import "mainDivs";*/
/*@import "start";*/
ul * {
  -webkit-backface-visibility: hidden; }

/* Safari 6.1+ (9.0 is the latest version of Safari at this time) */
@media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
  html, body {
    position: relative;
    overflow: hidden; } }

:focus {
  outline: none !important; }

body, html {
  width: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1;
  font-size: 16px;
  letter-spacing: 0.15em;
  overflow: initial;
  text-rendering: optimizeLegibility; }

html:lang(en) h1:lang(de), html:lang(en) h2:lang(de), html:lang(en) h3:lang(de), html:lang(en) h4:lang(de), html:lang(en) p:lang(de), html:lang(en) span:lang(de), html:lang(en) div:lang(de), html:lang(en) a:lang(de), html:lang(en) table:lang(de), html:lang(en) input:lang(de), html:lang(de) h1:lang(en), html:lang(de) h2:lang(en), html:lang(de) h3:lang(en), html:lang(de) h4:lang(en), html:lang(de) p:lang(en), html:lang(de) span:lang(en), html:lang(de) div:lang(en), html:lang(de) a:lang(en), html:lang(de) table:lang(en), html:lang(de) input:lang(en) {
  display: none !important; }

.load section, .load .banderole {
  display: block; }

#start-main.load {
  display: block; }

body, html {
  height: 100%;
  width: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1;
  font-size: 16px;
  letter-spacing: 0.15em;
  /*overflow: hidden;*/ }
  @media (min-width: 1500px) {
    body, html {
      font-size: 20px; } }
  @media (min-width: 2000px) {
    body, html {
      font-size: 21px; } }

body {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  font-size: .75rem;
  font-family: Titillium;
  background-color: white; }

p, span, h1, h2 {
  hyphens: auto; }

.mob_br {
  display: none; }

a:hover, a:link, a:active, a:visited {
  font-family: 'titillium', Titillium, sans-serif;
  font-weight: 400;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: color 250ms linear;
  -moz-transition: color 250ms linear;
  -ms-transition: color 250ms linear;
  -o-transition: color 250ms linear;
  transition: color 250ms linear;
  white-space: nowrap; }

a:hover {
  color: #b7ada5; }

ol, ul {
  list-style: none; }

li {
  display: inline-block; }

div#mouseCon {
  height: 100%;
  display: block; }

.scroll {
  position: absolute;
  display: none; }

div.active {
  display: block;
  opacity: 100; }

.anim1 {
  -webkit-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  transition: all 1500ms ease; }

.ol_bg {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: center top;
  display: none;
  z-index: 9; }

#kontakt-main {
  z-index: 11; }

#start-main {
  top: 100%;
  z-index: 10; }

#suite1-main {
  z-index: 8; }

#suite2-main {
  z-index: 7; }

#suite3-main {
  z-index: 6; }

#service-main {
  z-index: 5; }

#presse-main {
  z-index: 4; }

div.fastAnim {
  -webkit-transition: transform 1000ms ease-out;
  -moz-transition: transform 1000ms ease-out;
  -ms-transition: transform 1000ms ease-out;
  -o-transition: transform 1000ms ease-out;
  transition: transform 1000ms ease-out; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

#loader {
  float: left;
  z-index: 12;
  width: 100%;
  position: absolute; }
  #loader, #loader #loader-bg {
    transition: opacity 1500ms linear 500ms; }
  #loader h2 {
    animation: fade-in 1500ms;
    text-align: center;
    white-space: nowrap; }
  #loader.goAway {
    opacity: 0; }
  #loader #loader-bg {
    width: 69%;
    padding-left: 4px;
    float: left;
    box-sizing: border-box; }
    @media (max-width: 1280px) {
      #loader #loader-bg {
        width: 65%; } }
    @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
      #loader #loader-bg {
        width: 60%; } }
    @media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
      #loader #loader-bg {
        width: 100%; } }
    @media (max-height: 768px) and (max-width: 1024px) and (min-aspect-ratio: 1 / 1) {
      #loader #loader-bg {
        width: 60%; } }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      #loader #loader-bg {
        width: 100%; } }
  @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
    #loader.banderole4-img1 {
      background-position: 100% 86%; } }
  @media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
    #loader.banderole4-img1 {
      background-position: 100% 86%; } }
  @media (max-height: 768px) and (max-width: 1024px) and (min-aspect-ratio: 1 / 1) {
    #loader.banderole4-img1 {
      background-position: 100% 86%; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    #loader.banderole4-img1 {
      background-position: 100% 67%; } }
  #loader .banderole-aside {
    right: 0; }
    @media (max-width: 1280px) {
      #loader .banderole-aside {
        width: 35%; } }
    @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
      #loader .banderole-aside {
        width: 40%; } }
    @media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
      #loader .banderole-aside {
        width: 65%; } }
    @media (max-height: 768px) and (max-width: 1024px) and (min-aspect-ratio: 1 / 1) {
      #loader .banderole-aside {
        width: 40%; } }
    @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
      #loader .banderole-aside {
        width: 100%; } }
    @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
      #loader .banderole-aside {
        width: 100%; } }

.svgs {
  display: none; }

.media-query {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  z-index: 999999; }
  .media-query div {
    width: 20%;
    float: left;
    background-color: red; }
  @media (max-width: 900px) and (max-aspect-ratio: 1 / 1) {
    .media-query #ipad-v {
      background-color: green; } }
  @media (max-height: 768px) and (max-width: 1024px) and (min-aspect-ratio: 1 / 1) {
    .media-query #ipad-h {
      background-color: green; } }
  @media (max-width: 1280px) and (min-aspect-ratio: 17 / 10) {
    .media-query #small-wide {
      background-color: green; } }
  @media (max-width: 650px) and (max-aspect-ratio: 1 / 1) {
    .media-query #mobileV {
      background-color: green; } }
  @media (max-height: 580px) and (min-aspect-ratio: 1 / 1) and (orientation: landscape) {
    .media-query #mobileH {
      background-color: green; } }

@mixin burger($strength,$c1,$c2) {
	div {
		position:absolute;
		left:0;
		width:100%;
		height:$strength;
		background-color:$c1;
		outline: 1px solid transparent;
		transform-origin: 0px 0px;

	}
	.n1 {
		top:56%;
		@include transition(transform 1000ms ease 50ms, background-color 1s ease 50ms);
	}
	.n2 {top:28%;
		@include transition(opacity 500ms linear 500ms, background-color 1s ease);
	}
	.n3 {@include transition(transform 1000ms ease 50ms, background-color 1s ease 50ms);}
	&.active {
		div	 {
			background-color:$c2;
		}
		.n1 {
			/*top:100%;*/
			@include transition(transform 1000ms ease, background-color 1s ease);
			/*transform:translateY(15px) rotate3d(0,0,1,-45deg) scale(1.4142,1.4142);*/
			transform: translate3d(0,15px,0) rotate3d(0,0,1,-45deg) scale3d(1.4142, 1.2, 1);
		}
		.n2 {		
			@include transition(opacity 50ms linear, background-color 1s ease 50ms);
			opacity:0;		
		}
		.n3 {
			top:0;
			/*transform:rotate3d(0,0,1,45deg) scale(1.4142,1.4142);*/
			transform:rotate3d(0,0,1,45deg) scale3d(1.4142, 1.2, 1);
			@include transition(transform 1000ms ease, background-color 1s ease);
		}
	}
	&.blau div {background-color: $blauText;}
}
@mixin burger-vertical($height,$width, $c1,$c2) {
	transition: left 1s 0s,top 1s 0s, height 1s 0s;
	div	 {
		position:absolute;
		left: 0;
		top: 0;
		width: $width;
		height: 100%;
		background-color: $c1;
		transition: transform 500ms 0s, margin 500ms 500ms,  height 1s 0s, background-color 1s 0s;
		transform: translateX(50%);
	}
	.n1 {
		margin-left: 38%;
	}
	.n2 {
		margin-left: 62%;
	}
	&.active {
		height: 1.41*$height;
		div {
			transition: transform 500ms 500ms, margin 500ms 0s, height 1s 0s, background-color 1s 0s;
		}
		.n1 {
			margin-left: 50%;
			transform: translateX(50%) rotate(45deg);	
		}
		.n2 {		
			margin-left: 50%;
			transform: rotate(-45deg);
		}
	}
}
@mixin pfeil($strength,$c1,$c2) {
	div	 {
		position:absolute;
		left:50%;
		top:50%;
		width:50%;
		height:$strength;
		background-color:$c1;
		outline: 1px solid transparent;
		transform-origin: 0px 1px;
		z-index:100;	
		@include transition(background-color 1s ease);

	}
	.n1 {
		transform: translate3d(-50%,-20%,0) rotate3d(0,0,1,-45deg) scale(0.7071,1);
	}
	.n2 {
		transform: translate3d(-50%,-50%,0);
	}
	.n3 {
		transform: translate3d(-50%,-70%,0) rotate3d(0,0,1,45deg) scale(0.7071,1);
	}
	&:after {			
		content: "";
			display: block;
			padding-top: 100%;
			width: 100%;
/*			margin-top: -50%;
			margin-left: -50%;*/
			position: relative;
			border-radius: 100%;
			outline: 1px solid transparent;
			background-color:transparent;
			 @include transition(background-color 1s ease);

	}
	&.invers {
		&:after {
			background-color: rgba(0, 145, 207, 0.8);
		}
		div	 {
			background-color:$c2;
		}
	}
	&.weiss {
		div {
			background-color: $c2;
		}
	}
}
@mixin prefix($attr,$val) {
	-webkit-#{$attr}: $val;
    -moz-#{$attr}: $val;
    -ms-#{$attr}: $val;
    -o-#{$attr}: $val;
    #{$attr}: $val;
}
@mixin transition($transition...) {
    @include prefix(transition,$transition);
}
@mixin pos($t,$r:$t,$b:$t,$l:$r) {
	top: $t;
	right: $r;
	bottom: $b;
	left: $l;
}
@mixin translate3d($x:0,$y:0,$z:0) {
	@include prefix(transform,translate3d($x,$y,$z));
}
@mixin transFade($o:opacity, $t:0.5s, $f: linear) {
	@include transition($o $t $f);
}
@mixin transformCenter {
	position:absolute;
        top:50%;
        left:50%;
        transform:translate3d(-50%,-50%,0);
}
@mixin transformCenterV {
	position:absolute;
        top:50%;
        transform:translate3d(0,-50%,0);
}

@mixin bp($point) {
   @if $point == desktop {
     @media (min-width: 70em) { @content ; }
  }
   @else if $point == laptop {
     @media (min-width: 64em) { @content ; }
  }
   @else if $point == imac {
     @media (min-width: 1500px) { @content ; }
  }
   @else if $point == imacbig {
     @media (min-width: 2000px) { @content ; }
  }
    @else if $point == smaller {
     @media (max-width: 1280px) { @content ; }
  }
   @else if $point == smallWide {
     @media (max-width: 1280px) and (min-aspect-ratio: 17/10) { @content ; }
  }
   @else if $point == ipadV {
    @media (max-width: 900px) and (max-aspect-ratio: 1/1)  { @content ; }
  }
  @else if $point == ipadH {
    @media (max-height: 768px) and (max-width:1024px ) and (min-aspect-ratio: 1/1)  { @content ; }
  }
  @else if $point == mobileV {
     @media (max-width: 650px)  and (max-aspect-ratio: 1/1) { @content ; }
  }
    @else if $point == ip5v {
     @media (max-height: 580px)  and (max-aspect-ratio: 2/1) { @content ; }
  }
    @else if $point == ip4v {
     @media (max-height: 480px)  and (max-aspect-ratio: 1/1) { @content ; }
  }
@else if $point == mobileH {
   @media (max-height: 580px)  and (min-aspect-ratio: 1/1) and (orientation: landscape) { @content ; }
}
  @else if $point == mobileonly {
     @media (max-width: 37.5em)  { @content ; }
  }
}
